import {
  CLEAN_PROMOTER,
  GET_PROMOTERS,
  GET_PROMOTERS_BY_COUPON,
  MANAGE_PROMOTER, SEARCHROWSPERPAGE,
  SET_PROMOTER,
} from '../actionTypes';

import Common from '../../src/services/common.js';

export const managePromoter = (values) => async dispatch => {
  return await dispatch({
    type: MANAGE_PROMOTER,
    payload: values
  })
}

export const setPromoter = (values) => async dispatch => {
  return await dispatch({
    type: SET_PROMOTER,
    payload: values
  })
}

export const cleanPromoter = () => async dispatch => {
  return await dispatch({
    type: CLEAN_PROMOTER,
  })
}

export const getAllPromoters = () => async dispatch => {
  return await Common.get_promoters().then(async (r) => {
    await dispatch({
      type: GET_PROMOTERS,
      payload: r.data,
    });
    return { spinnerScreen: false };
  }).catch(e => {
    return {
      spinnerScreen: false,
      openAlert: true,
      messageAlert: e.response && e.response.data ?  e.response.data.error.message : 'Hubo un error al obtener los promotores, intente nuevamente.',
    }
  });
};

export const getPromoterByCoupon = (values, list) => async dispatch => {
  return await Common.validate_coupon(values).then(async (r) => {
    const promoters = list.filter(l => l.coupons.some(c => c.id === r.data.coupon));
    await dispatch({
      type: SEARCHROWSPERPAGE,
      payload: { values: { page: 0, rowsPerPage: 5 } },
    });
    await dispatch({
      type: GET_PROMOTERS,
      payload: promoters,
    });
    return { spinnerScreen: false };
  }).catch(e => {
    return {
      spinnerScreen: false,
      openAlert: true,
      messageAlert: e.response && e.response.data ?  e.response.data.error.message : 'Hubo un error al obtener los promotores, intente nuevamente.',
    }
  });
}

export const createPromoter = (data) => async () => {
  return Common.create_promoter(data).then(() => {
    return {
      success: true,
      spinnerScreen: false,
      showDialog: false,
      openAlert: true,
      messageAlert: 'Nuevo producto registrado exitosamente.',
    }
  }).catch((e) => ({
    spinnerScreen: false,
    openAlert: true,
    messageAlert: e.response && e.response.data ?  e.response.data.error.message : 'Hubo un error al crear el promotor, intente nuevamente.',
  }))
}

export const updateBasicPromoter = (data) => async () => {
  return Common.update_basic_promoter(data).then(r => {
    return {
      success: true,
      spinnerScreen: false,
      openAlert: true,
      messageAlert: 'Promotor actualizado exitosamente.',
    }
  }).catch((e) => {
    return {
      spinnerScreen: false,
      openAlert: true,
      messageAlert: e.response && e.response.data ?  e.response.data.error.message : 'Hubo un error al actualizar el promotor, intente nuevamente.',
    }
  })
}

export const updateCouponsPromoter = (data) => async () => {
  return Common.update_coupons_promoter(data).then(() => {
    return {
      success: true,
      spinnerScreen: false,
      showDialog: false,
      coupons: false,
      openAlert: true,
      messageAlert: 'Cupón agregado exitosamente.',
    }
  }).catch((e) => {
    return {
      spinnerScreen: false,
      openAlert: true,
      messageAlert: e.response && e.response.data ?  e.response.data.error.message : 'Hubo un error al agregar el cupón, intente nuevamente.',
    }
  })
}