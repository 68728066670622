import {
  CLEAN_CANCELLATION,
  GET_ALL_CANCELLATION,
  PROFILE_STEP,
  SELECT_BENEFICIARY,
  SET_CANCELLATION,
  SHOW_EDIT_DIALOG,
  UPDATE_PAYMENT_INFO,
  SAVE_LINK,
  RENEW_PAYMENT,
  CHANGE_STATE_AFFILIATION,
  RENEW_ERROR,
  RENEW_LOADING,
} from "../actionTypes";
import Common from "../../src/services/common.js";

const FileDownload = require("js-file-download");

export const handleProfileStep = (values) => async (dispatch) =>
  await dispatch({
    type: PROFILE_STEP,
    payload: {
      values,
    },
  });

export const getTitularData = (values) => {
  return async (dispatch) => {
    return await Common.get_titular_data(values)
      .then((r) => {
        return {
          success: true,
          titular: r.data.titular,
          payment_info: r.data.payment_info,
          is_client: r.data?.is_client,
        };
      })
      .catch((e) => {
        return {
          success: false,
          openAlert: true,
          messageAlert:
            e.response && e.response.data
              ? e.response.data.error.message
              : "Hubo un error al consultar sus datos, intente nuevamente.",
        };
      });
  };
};

export const getTitularDocument = (userID) => {
  return async (dispatch) => {
    return await Common.get_titular_document(
      { responseType: "blob" },
      { userID }
    )
      .then((r) => {
        const name =
          r && r.headers && r.headers["file-name"]
            ? r.headers["file-name"]
            : "documento";
        FileDownload(r.data, name);
      })
      .catch((e) => {
        return {
          success: false,
          openAlert: true,
          messageAlert:
            e.response && e.response.data && e.response.data.error
              ? e.response.data.error.message
              : "Hubo un error al descargar el documento, intente nuevamente.",
        };
      });
  };
};

export const updateTitularData = (titular) => {
  return async (dispatch) => {
    return await Common.update_titular(titular)
      .then((r) => {
        return {
          success: true,
          showDialog: false,
          openAlert: true,
          messageAlert: "Su información ha sido actualizada exitosamente.",
        };
      })
      .catch((e) => {
        return {
          success: false,
          openAlert: true,
          messageAlert:
            e.response && e.response.data
              ? e.response.data.error.message
              : "Hubo un error al actualizar sus datos, intente nuevamente.",
        };
      });
  };
};

export const getBeneficiariesTitular = (id_titular) => {
  return async (dispatch) => {
    return await Common.get_beneficiaries_titular(id_titular)
      .then((r) => {
        return {
          success: true,
          beneficiaries:
            r.data && r.data.beneficiaries ? r.data.beneficiaries : [],
        };
      })
      .catch((e) => {
        return {
          success: false,
          openAlert: true,
          messageAlert:
            e.response && e.response.data
              ? e.response.data.error.message
              : "Hubo un error al actualizar sus datos, intente nuevamente.",
        };
      });
  };
};

export const changePassword = (data) => {
  return async (dispatch) => {
    return await Common.change_password(data)
      .then(() => {
        return {
          success: true,
          showDialog: false,
          openAlert: true,
          messageAlert: "Su contraseña ha sido actualizada exitosamente.",
        };
      })
      .catch((e) => {
        return {
          success: false,
          openAlert: true,
          messageAlert:
            e.response && e.response.data
              ? e.response.data.error.message
              : "Hubo un error al actualizar sus contraseña, intente nuevamente.",
        };
      });
  };
};

export const updatePhoto = (data) => {
  return async (dispatch) => {
    return await Common.update_photo(data)
      .then((r) => {
        return {
          success: true,
          showDialog: false,
          openAlert: true,
          messageAlert: r.data.message
            ? r.data.message
            : "Su imagen de perfil ha sido actualizada exitosamente.",
          picture: r.data.picture,
        };
      })
      .catch((e) => {
        return {
          success: false,
          openAlert: true,
          messageAlert:
            e.response && e.response.data
              ? e.response.data.error.message
              : "Hubo un error al actualizar su imagen de perfil, intente nuevamente.",
        };
      });
  };
};

export const getBeneficiariesData = (values) => {
  return async (dispatch) => {
    return await Common.get_beneficiaries_data(values)
    .then((r) => {
      return {
        success: true,
        beneficiaries: r.data.beneficiaries,
        titular: r.data.titular,
        customers: r.data.customers,
      }
    })
    .catch((e) => {
      return {
        success: false,
        openAlert: true,
        messageAlert: e.response && e.response.data ?  e.response.data.error.message : 'Hubo un error al consultar sus datos, intente nuevamente.',
      }
    })
  }
}

export const select_beneficiary = (values) => {
  return async (dispatch) =>
    await dispatch({
      type: SELECT_BENEFICIARY,
      payload: {
        values,
      },
    });
};

export const show_edit_dialog = (values) => async (dispatch) =>
  await dispatch({
    type: SHOW_EDIT_DIALOG,
    payload: {
      values,
    },
  });

export const updateBeneficiaryData = (values) => {
  return async (dispatch) => {
    return await Common.update_beneficiary(values)
      .then((r) => {
        return {
          showLoaddingImg: false,
          success: true,
          openAlert: true,
          messageAlert: "El beneficiario ha sido actualizado exitosamente",
        };
      })
      .catch((e) => {
        return {
          showLoaddingImg: false,
          success: false,
          openAlert: true,
          messageAlert:
            e.response && e.response.data
              ? e.response.data.error.message
              : "Hubo un error al actualizar los datos del beneficiario, intente nuevamente.",
        };
      });
  };
};

export const cancellationRequest = (data) => {
  return async (dispatch) => {
    return await Common.cancellation_request(data)
      .then(() => {
        return {
          motive: "",
          success: true,
          showConfirm: false,
          openAlert: true,
          messageAlert: "Su solicitud ha sido enviada.",
        };
      })
      .catch((e) => {
        return {
          success: false,
          openAlert: true,
          messageAlert:
            e.response && e.response.data
              ? e.response.data.error.message
              : "Hubo un error al enviar la solicitud, intente nuevamente.",
        };
      });
  };
};

export const getAdvancedConfigData = (values) => {
  return async (dispatch) => {
    return await Common.get_advanced_config_data(values)
      .then((r) => {
        return {
          success: true,
          benefits: r.data.benefits,
          beneficiaries: r.data.beneficiaries,
          payment_info: r.data.payment_info,
        };
      })
      .catch((e) => {
        return {
          success: false,
          openAlert: true,
          messageAlert:
            e.response && e.response.data
              ? e.response.data.error.message
              : "Hubo un error al consultar sus datos, intente nuevamente.",
        };
      });
  };
};

export const sendProfileDocuments = () => {
  return async (dispatch) => {
    return await Common.send_profile_documents()
      .then(() => {
        return {
          showSpinner: false,
          openAlert: true,
          messageAlert: "Los documentos han sido enviados exitosamente.",
        };
      })
      .catch((e) => {
        return {
          showSpinner: false,
          openAlert: true,
          messageAlert:
            e.response && e.response.data
              ? e.response.data.error.message
              : "Hubo un error al enviar los documentos, intente nuevamente.",
        };
      });
  };
};

export const savePaymentInfo = (values) => async (dispatch) =>
  await dispatch({
    type: UPDATE_PAYMENT_INFO,
    payload: {
      values,
    },
  });

export const updatePaymentInfo = (values) => {
  return async (dispatch) => {
    return await Common.update_payment_info(values)
      .then(() => {
        return {
          success: true,
          disabledInputs: true,
          spinnerScreen: false,
          openAlert: true,
          messageAlert:
            "Su información de pago ha sido actualizada correctamente.",
        };
      })
      .catch((e) => {
        return {
          spinnerScreen: false,
          openAlert: true,
          messageAlert:
            e.response && e.response.data
              ? e.response.data.error.message
              : "Hubo un error al actualizar sus datos de pago, intente nuevamente.",
        };
      });
  };
};

export const cancellFreeTrial = (data) => {
  return async (dispatch) => {
    return await Common.annulled_free_trial(data)
      .then(() => {
        return {
          motive: "",
          success: true,
          showConfirm: false,
          openAlert: true,
          messageAlert: "Su solicitud ha sido enviada.",
        };
      })
      .catch((e) => {
        return {
          success: false,
          openAlert: true,
          messageAlert:
            e.response && e.response.data
              ? e.response.data.error.message
              : "Hubo un error al enviar la solicitud, intente nuevamente.",
        };
      });
  };
};

export const updateCancellationRequest = (data) => {
  return async (dispatch) => {
    return Common.update_cancellation_request(data)
      .then(() => {
        return {
          success: true,
          spinnerScreen: false,
          showDialog: false,
          openAlert: true,
          messageAlert: "Anulación actualizada exitosamente.",
        };
      })
      .catch((e) => ({
        spinnerScreen: false,
        openAlert: true,
        messageAlert:
          e.response && e.response.data
            ? e.response.data.error.message
            : "Hubo un error al actualizar la anulación, intente nuevamente.",
      }));
  };
};

export const getAllCancellations = () => async (dispatch) => {
  return await Common.get_all_cancellation_request()
    .then(async (r) => {
      await dispatch({
        type: GET_ALL_CANCELLATION,
        payload: r.data.cancellation_request,
      });
      return { spinnerScreen: false };
    })
    .catch((e) => {
      return {
        spinnerScreen: false,
        openAlert: true,
        messageAlert:
          e.response && e.response.data
            ? e.response.data.error.message
            : "Hubo un error al obtener las devoluciones, intente nuevamente.",
      };
    });
};

export const getCancellation = (data) => async (dispatch) => {
  return await Common.get_cancellation_request(data)
    .then(async (r) => {
      await dispatch({
        type: SET_CANCELLATION,
        payload: r.data.cancellation_request,
      });
      return { spinnerScreen: false };
    })
    .catch((e) => {
      return {
        spinnerScreen: false,
        openAlert: true,
        messageAlert:
          e.response && e.response.data
            ? e.response.data.error.message
            : "Hubo un error al obtener las devoluciones, intente nuevamente.",
      };
    });
};

export const filterCancellations = (data) => async (dispatch) => {
  return await Common.filter_cancellation_request(data)
    .then(async (r) => {
      await dispatch({
        type: GET_ALL_CANCELLATION,
        payload: r.data.cancellation_request,
      });
      return { spinnerScreen: false };
    })
    .catch((e) => {
      return {
        spinnerScreen: false,
        openAlert: true,
        messageAlert:
          e.response && e.response.data
            ? e.response.data.error.message
            : "Hubo un error al obtener las devoluciones, intente nuevamente.",
      };
    });
};

export const set_cancellation = (values) => {
  return async (dispatch) =>
    await dispatch({
      type: SET_CANCELLATION,
      payload: values,
    });
};

export const clean_cancellation = (values) => {
  return async (dispatch) =>
    await dispatch({
      type: CLEAN_CANCELLATION,
    });
};

export function SaveSharedLink(values) {
  return async (dispatch) => {
    await dispatch({
      type: SAVE_LINK,
      payload: {
        values,
      },
    });
    return true;
  };
}

// Report payments
export const ListPaymentFees = (paymentID) => async (dispatch) => {
  return await Common.list_payment_fees(paymentID)
    .then(async (r) => {
      return {
        fees: r.data.fees,
        openAlert: r.data.fees?.length === 0 ? true : false,
        messageAlert:
          r.data.fees?.length === 0
            ? "¡Felicidades!, no posee cuotas pendientes por pagar."
            : "",
      };
    })
    .catch((e) => {
      return {
        openAlert: true,
        messageAlert: e?.response?.data?.error?.message
          ? e.response.data.error.message
          : "Hubo un error al obtener la información, intente nuevamente.",
      };
    });
};

export const ReportPaymentFees = (data) => async (dispatch) => {
  return await Common.report_payment_fees(data)
    .then(async (r) => {
      return {
        success: true,
        openAlert: true,
        messageAlert: r.data?.message,
      };
    })
    .catch((e) => {
      return {
        openAlert: true,
        messageAlert: e?.response?.data?.error?.message
          ? e.response.data.error.message
          : "Hubo un error al reportar su pago, intente nuevamente.",
      };
    });
};

export const editReportedPaymentFees = (data) => async (dispatch) => {
  return await Common.edit_reported_payment_fees(data)
    .then(async (r) => {
      return {
        success: true,
        openAlert: true,
        messageAlert: r.data?.message,
      };
    })
    .catch((e) => {
      return {
        openAlert: true,
        messageAlert: e?.response?.data?.error?.message
          ? e.response.data.error.message
          : "Hubo un error al enviar su pago, intente nuevamente.",
      };
    });
};

// RENEW
export const beneficiariesToRenew = () => async (dispatch) => {
  await dispatch({ type: RENEW_LOADING, payload: true });
  return await Common.beneficiaries_to_renew()
    .then(async (r) => {
      await dispatch({ type: RENEW_ERROR, payload: "" }); // Limpiar el error
      await dispatch({
        type: RENEW_PAYMENT,
        payload: r?.data?.payments,
      });
      return {
        spinnerScreen: false,
        isoCode: r?.data.payments.currency,
        interval: r.data.payments.recurrent_interval,
      };
    })
    .catch(async (e) => {
      await dispatch({
        type: RENEW_PAYMENT,
        payload: [],
      });
      await dispatch({
        type: RENEW_ERROR,
        payload: e?.response?.data?.error?.message
          ? e.response.data.error.message
          : "Hubo un error al obtener los datos de renovación, intente nuevamente.",
      });
      return {
        openAlert: true,
        messageAlert: e?.response?.data?.error?.message
          ? e.response.data.error.message
          : "Hubo un error al obtener los datos de renovación, intente nuevamente.",
      };
    });
};

export const beneficiariesToUpdate = (paymentID) => async (dispatch) => {
  return await Common.beneficiaries_to_update(paymentID)
    .then(async (r) => {
      let customers = r?.data.customers;
      customers.map((c) => (c.formErrors = []));
      await dispatch({
        type: CHANGE_STATE_AFFILIATION,
        payload: {
          values: {
            data: {
              name: "beneficiaries",
              value: customers,
            },
          },
        },
      });
      return { spinnerScreen: false, success: true };
    })
    .catch((e) => {
      return {
        openAlert: true,
        messageAlert: e?.response?.data?.error?.message
          ? e.response.data.error.message
          : "Hubo un error al obtener los datos de los beneficiarios, intente nuevamente.",
      };
    });
};

export const nonRenewalBeneficiary = (beneficiaryID) => async (dispatch) => {
  return await Common.non_renewal_beneficiary(beneficiaryID)
    .then(async (r) => {
      return {
        loading: false,
        success: true,
        openAlert: true,
        messageAlert: r?.data?.message
          ? r.data.message
          : "El beneficiario a sido excluido",
      };
    })
    .catch((e) => {
      return {
        openAlert: true,
        messageAlert: e?.response?.data?.error?.message
          ? e.response.data.error.message
          : "Hubo un error al excluir al beneficiario, intente nuevamente.",
      };
    });
};

export const saveRenewalData = (values) => {
  return async (dispatch) => {
    return await Common.save_renewal_data(values)
      .then(async (res) => {
        let beneficiaries = res.data?.beneficiaries;
        beneficiaries.map((b) => {
          b.formErrors = [];
          return b;
        });
        await dispatch({
          type: CHANGE_STATE_AFFILIATION,
          payload: {
            values: {
              data: {
                name: "beneficiaries",
                value: beneficiaries,
              },
            },
          },
        });
        return {
          beneficiaries,
          savedSuccess: true,
          loading: false,
          success: true,
        };
      })
      .catch((e) => {
        return {
          loading: false,
          openAlert: true,
          messageAlert: e?.response?.data?.error?.message
            ? e.response.data.error.message
            : "Hubo un error al guardar su información, intente nuevamente.",
        };
      });
  };
};

export const paymentMethodsForRenew = (values) => {
  return async (dispatch) => {
    return await Common.payment_methods_renewal(values)
      .then((r) => {
        return {
          success: true,
          paymentMethods: r.data?.payment_methods,
          promotion: r.data?.promotion,
          loading: false,
        };
      })
      .catch((e) => {
        return {
          loading: false,
          success: false,
          openAlert: true,
          messageAlert: e?.response?.data?.error?.message
            ? e.response.data.error.message
            : "Hubo un error al consultar los metodos de pago, intente nuevamente.",
        };
      });
  };
};

export const payRenewal = (values) => {
  return async (dispatch) => {
    return await Common.pay_renewal(values)
      .then((res) => {
        return {
          success: true,
          loading: false,
          statusPayment: res.data.statusPayment,
          paymentResponse: res.data.paymentResponse,
          paymentSubResponse: res.data.paymentSubResponse,
        };
      })
      .catch((e) => {
        return {
          success: false,
          loading: false,
          statusPayment: false,
          paymentResponse: e?.response?.data?.error?.message
            ? e.response.data.error.message
            : "Hubo un error al procesar su pago, intente nuevamente.",
        };
      });
  };
};

export const nonRenewalSubscription = (values) => {
  return async (dispatch) => {
    return await Common.non_renewal_subscription(values)
      .then((r) => {
        return {
          loading: false,
          success: true,
          openAlert: true,
          messageAlert: r?.data?.message
            ? r.data.message
            : "Su solicitud fue enviada exitosamente.",
        };
      })
      .catch((e) => {
        return {
          loading: false,
          openAlert: true,
          messageAlert: e?.response?.data?.error?.message
            ? e.response.data.error.message
            : "Hubo un error al cancelar la suscripción, intente nuevamente.",
        };
      });
  };
};

export const renewSubscription = (values) => {
  return async (dispatch) => {
    return await Common.auto_renew_subscription(values)
      .then((res) => {
        return {
          success: true,
          loading: false,
          statusPayment: res?.data?.statusPayment,
          paymentResponse: res?.data?.paymentResponse,
          openAlert: true,
          messageAlert: res?.data?.paymentResponse,
        };
      })
      .catch((e) => {
        return {
          loading: false,
          statusPayment: false,
          openAlert: true,
          messageAlert: e?.response?.data?.error?.message
            ? e.response.data.error.message
            : "Hubo un error al procesar su pago, intente nuevamente.",
          paymentResponse: e?.response?.data?.error?.message
            ? e.response.data.error.message
            : "Hubo un error al procesar su pago, intente nuevamente.",
        };
      });
  };
};

export const getPaymentBill = (paymentID) => {
  return async (dispatch) => {
    return await Common.get_payment_bill(
      { responseType: "blob" },
      { paymentID }
    )
      .then(async (r) => {
        await FileDownload(r.data, "factura.pdf");
        return {
          messageAlert: "Descargando archivo, por favor espere.",
        };
      })
      .catch((e) => {
        return {
          openAlert: true,
          messageAlert: e?.response?.data?.error?.message
            ? e.response.data.error.message
            : "Hubo un error al descargar las facturas, intente nuevamente.",
        };
      });
  };
};

export const assignAddonToBeneficiaryRenew = (values) => async (dispatch) => {
  return await Common.assignAddonRenew(values)
    .then((res) => {
      return {
        success: true,
        customer: res.data.customer,
        payment_methods: res.data.payment_methods,
        showSpinner: false,
        openAlert: true,
        messageAlert: res.data?.message ?? "Asignado con éxito.",
      };
    })
    .catch((e) => {
      return {
        success: false,
        showSpinner: false,
        openAlert: true,
        messageAlert: e?.response?.data?.error?.message
          ? e.response.data.error.message
          : "Hubo un error al agregar el addon, intente nuevamente.",
      };
    });
};
