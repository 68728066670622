import {
  SET_COMMISSIONS,
  SET_COMMISSION,
} from '../actionTypes';
import Common from '../../src/services/common.js';

export const getAllCommissions = () => {
  return async dispatch => {
    return Common.get_commissions(0).then(async (r) => {
      await dispatch({
        type: SET_COMMISSIONS,
        payload: r.data.commission_scheme,
      });
      return { spinnerScreen: false };
    }).catch(e => {
      return {
        spinnerScreen: false,
        openAlert: true,
        messageAlert: e.response && e.response.data ?  e.response.data.error.message : 'Hubo un error al obtener las comisiones, intente nuevamente.',
      }
    });
  };
};

export const createCommission = (data) => {
  return async dispatch => {
    return Common.create_commissions(data).then(() => {
      return {
        success: true,
        spinnerScreen: false,
        showDialog: false,
        openAlert: true,
        messageAlert: 'Nueva comisión registrada exitosamente.',
      }
    }).catch((e) => {
      return {
        spinnerScreen: false,
        openAlert: true,
        messageAlert: e.response && e.response.data ? e.response.data.error.message : 'Hubo un error al crear la comisión, intente nuevamente.',
      }
    })
  }
}

export const updateCommission = (data) => {
  return async dispatch => {
    return Common.update_commissions(data).then(() => {
      return {
        success: true,
        spinnerScreen: false,
        showDialog: false,
        openAlert: true,
        messageAlert: 'Comisión actualizada exitosamente.',
      }
    }).catch((e) => {
      return {
        spinnerScreen: false,
        openAlert: true,
        messageAlert: e.response && e.response.data ?  e.response.data.error.message : 'Hubo un error al actualizar la comisión, intente nuevamente.',
      }
    })
  }
}

export const setCommissionData = (data) => {
  return async dispatch => {
    return await dispatch({
      type: SET_COMMISSION,
      payload: data,
    })
  }
}
