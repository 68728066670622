import {
  SET_CONDITION,
  SET_CONDITIONS,
  SET_LIST_PARAMETERS,
} from '../actionTypes';
import Common from '../../src/services/common.js';

export const getAllConditions = () => {
  return async dispatch => {
    return Common.get_conditions().then(async (r) => {
      await dispatch({
        type: SET_CONDITIONS,
        payload: r.data.points,
      });
      return { spinnerScreen: false };
    }).catch(e => {
      return {
        spinnerScreen: false,
        openAlert: true,
        messageAlert: e.response && e.response.data ?  e.response.data.error.message : 'Hubo un error al obtener las condiciones, intente nuevamente.',
      }
    });
  };
};

export const getListParameters = (type) => {
  return async dispatch => {
    return Common.get_list_parameters(type).then(async (r) => {
      await dispatch({
        type: SET_LIST_PARAMETERS,
        payload: {type: `${type}Parameters`, value: r.data.list_parameters},
      });
      return { spinnerScreen: false };
    }).catch(e => {
      return {
        spinnerScreen: false,
        openAlert: true,
        messageAlert: e.response && e.response.data ?  e.response.data.error.message : 'Hubo un error al obtener los parametros, intente nuevamente.',
      }
    });
  };
};

export const createCondition = (data) => {
  return async dispatch => {
    return Common.create_condition(data).then(() => {
      return {
        success: true,
        spinnerScreen: false,
        showDialog: false,
        openAlert: true,
        messageAlert: 'Nueva condición registrado exitosamente.',
      }
    }).catch((e) => {
      return {
        spinnerScreen: false,
        openAlert: true,
        messageAlert: e.response && e.response.data ? e.response.data.error.message : 'Hubo un error al crear la condición, intente nuevamente.',
      }
    })
  }
}

export const updateCondition = (id, data) => {
  return async dispatch => {
    return Common.update_condition(id, data).then(() => {
      return {
        success: true,
        spinnerScreen: false,
        showDialog: false,
        openAlert: true,
        messageAlert: 'Condición actualizada exitosamente.',
      }
    }).catch((e) => {
      return {
        spinnerScreen: false,
        openAlert: true,
        messageAlert: e.response && e.response.data ?  e.response.data.error.message : 'Hubo un error al actualizar la condición, intente nuevamente.',
      }
    })
  }
}

export const setConditionData = (data) => {
  return async dispatch => {
    return await dispatch({
      type: SET_CONDITION,
      payload: data,
    })
  }
}
