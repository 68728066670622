import Api from './api';

const company_code = 24;

const payment_affiliation = (data) => Api().post('/v1/payment', data);
const save_data_customer = (data) => Api().post('/v2/payments/save-data', data);
const send_link_payment = (data) =>
  Api().post('/customers/venemed/send-link-payment', data);

const send_acunsa_code = (data) => Api().post('/v2/clients/accept-tyc-acunsa', data);
const plans = (campaign, age, has_benefits) =>
  Api().get(`/plans/age/${company_code}/${campaign}/${age}/${has_benefits}`);
const validate = (data) =>
  Api().post(`/customers-validation/validate-campaing`, data);

const affiliations = (data) => Api().post(`/affiliations/`, data);
const assignAddon = (data) =>
  Api().post('/v2/addons/save-addons-beneficiary', data);

const save_data_customers = (data) =>
  Api().post('/v3/customers/save-beneficiaries', data);

// REMOVE BENEFIARIES
const remove_temp_beneficiaries = (beneficiary_sha) =>
  Api().delete('/v2/clients/beneficiary', { data: beneficiary_sha });

// HEALTH RESUME
const customer_resume = (data) => Api().post('/v3/customers/summary', data);
const save_health_data = (data) =>
  Api().post('/v3/customers/save-health-data', data);

// ADMIN
const leads = (leads) => Api().post(`/leads`, leads);
const get_leads = (status) => Api().post('/leads/filter', status);
const export_lead = (options, data) => Api(options).post('/leads/export', data);
const create_lead = (data) => Api().post('/v1/leads/custom', data);
const update_lead = (data) => Api().put('/v1/leads/custom', data);
const get_origin_filter = () => Api().get('/v1/leads/origins');
const get_status_filter = () => Api().get('/v1/leads/status');
const get_active_promotions = () => Api().get('/v1/promotions/active');

const get_proporsal_value = () => Api().get('/value-proposal');
const create_proposal_value = (data) => Api().post('/value-proposal', data);
const update_proposal_value = (data) => Api().put('/value-proposal', data);

const user_login = (users) => Api().post('/v1/users/login', users);
const find_all_users = () => Api().get('/v1/users');
const find_by_email = (email) => Api().post('/v1/users/find', email);
const user_update = (data) => Api().post('/v1/users/admin-update', data);
const create_user = (data) => Api().post('/v1/users', data);
const delete_user = (userId) => Api().delete('/v1/users', { data: userId });

const get_companies = () => Api().get('/companies');
const get_campaigns = () => Api().get('/campaigns');
const get_plans = () => Api().get('/plans');
const get_surveys = () => Api().get('/si/v2/surveys');
const get_addons = () => Api().get('/v1/addons');
const get_validators = () => Api().get('/v1/validator/');
const get_products = () => Api().get('/v1/products/');
const get_payment_methodsAll = () => Api().get('/v1/payment-methods/');
const get_promotions = () => Api().get('/v1/promotions/');

const create_campaign = (data) => Api().post('/campaigns', data);
const create_plan = (data) => Api().post('/plans', data);
const create_addon = (data) => Api().post('/v1/addons', data);
const create_validator = (data) => Api().post('/v1/validator/', data);
const create_payment_method = (data) =>
  Api().post('/v1/payment-methods/', data);
const create_promotion = (data) => Api().post('/v1/promotions/', data);

const update_campaign = (data) => Api().post('/campaigns/update', data);
const update_plan = (data) => Api().post('/plans/update', data);
const update_addon = (data) => Api().put('/v1/addons/update', data);
const update_validator = (data) => Api().put('/v1/validator/', data);
const update_payment_method = (data) => Api().put('/v1/payment-methods/', data);
const update_promotion = (data) => Api().put('/v1/promotions/', data);

const delete_addon = (addonId) =>
  Api().delete('/v1/addons/delete', { data: addonId });
const find_addon = (data) => Api().post('/v2/addons/find', data);

const find_addon_by_titular = (data) =>
  Api().post('/v2/addons/find-by-titular', data);
const save_addons_titular = (data) =>
  Api().post('/v2/addons/save-addons-titular', data);

const find_all_addons = (data) =>
  Api().post('/v3/addons/find-all-addons', data);
const save_all_addons = (data) =>
  Api().post('/v3/addons/save-all-addons', data);
const validate_addon_answers = (data) =>
  Api().post('/v3/addons/validate-addon-answers', data);

const interested_validation = (data) =>
  Api().post('/interested-validation/', data);
const interested_data = () => Api().get('/interested-validation/');

// Affiliation management
const data_customers = (params) => Api().get('/si/v1/customers/', { params });
const view_registry = (id) => Api().get(`/si/v1/customers/show/${id}`);
const search_customer = (params) =>
  Api().get('/si/v1/customers/find/', { params });
const send_documentation = (data) =>
  Api().post('/v1/clients/re-send-emails', data);
const view_titular_registry = (userID) =>
  Api().get(`/si/v1/customers/headline/show/${userID}`);
const data_pending = () => Api().get('si/v1/customers/find-alert-payments');
const annulled_subscription = (data) =>
  Api().post('/v1/payment/annulled-suscription', data);
const get_renewal_subscriptions = () =>
  Api().get('/si/v2/clients/get-renewal-subscriptions');

const update_registry = (registry) =>
  Api().post('/si/v2/payments/update-suscription', registry);

// ADMIN PAYMENTS
const get_tdc_payments = (params) =>
  Api().get('/si/v2/payments/find-alert-tdc-payments', { params });
const get_overdue_payments = (params) =>
  Api().get('/si/v2/payments/fees/overdue-payments', { params });
const get_pending_payments = (params) =>
  Api().get('/si/v2/payments/fees/pending-payments', { params });

const all_users_logs = () => Api().get('/vh/venemed/all-users-logs');
const user_logs_search = (parameter) =>
  Api().post('/vh/venemed/user-logs', parameter);

const export_data = (options, data) =>
  Api(options).post('/si/v1/exports/internal-report', data);
const getFilePayment = (options, data) =>
  Api(options).post('/vh/venemed/getFile', data);
const update_payment_fees = (data) =>
  Api().post('/si/v2/payments/fees/update-fees-list', data);

const marketing = (data) => Api(data).post('/marketing', data);
const get_marketing = () => Api().get('/marketing');
const update_marketing = (data) => Api(data).post('/marketing/update', data);
const delete_marketing = (campaign_id) =>
  Api().delete('/marketing', { data: campaign_id });
const view_marketing = (id) => Api().get(`/marketing/view/${id}`);
const export_campaign = (options, data) =>
  Api(options).post('/marketing/filter', data);

const get_medical_consults = () => Api().get('/v1/medical/consultation');
const get_consult_by_id = (id) => Api().get(`/v1/medical/consultation/${id}`);
const add_tracing = (data) => Api(data).put('/v1/medical/consultation/', data);
const change_consultation_status = (data) =>
  Api(data).put('/v1/medical/consultation/status/', data);
const export_medical_consults = (options) =>
  Api(options).get('/v1/medical/consultation/export');

const get_consults_alert = () =>
  Api().get('/v1/medical/consultation/alert-verification');
const get_volunteers_by_specialty = (specialty) =>
  Api().get(`/v1/medical/volunteers/specialty/${specialty}`);
const get_all_volunteers = () => Api().get('/v1/medical/volunteers');
const filter_volunteers = (data) =>
  Api().post('/v1/medical/volunteers/filter', data);
const update_volunteers = (data) => Api().put('/v1/medical/volunteers', data);

const get_data_attention = (attentionID) =>
  Api().get(`/v1/medical/attentions/data/${attentionID}`);
const process_medical_consult = (data) =>
  Api(data).post('/v1/medical/attentions/process', data);
const assignment_volunteer = (data) =>
  Api(data).post('/v1/medical/attentions/assignment', data);
const filter_consultation = (data) =>
  Api().post('/v1/medical/consultation/filter', data);

// ASISTENSI
const get_plans_by_country = (country) =>
  Api().get(`/v2/products/country/${country}`);
const products_quotation = (data) => Api().post('/v3/products/quotation', data);
const shared_quotation = (data) => Api().post('/v2/leads/quotation', data);
const get_plans_by_age = (data) =>
  Api().post('/v3/products/cotization-by-age', data);
const products_by_frequency = (data) =>
  Api().post('/v3/products/products-by-frequency', data);

const create_product = (data) => Api().post('/v1/products/', data);
const update_product = (data) => Api().put('/v1/products/', data);
const sign_up = (data) => Api().post('/v1/clients/sign-up', data);
const sign_up_social = (data) => Api().post('/v2/clients/sign-up-social', data);
const sign_in = (data) => Api().post('/v1/clients/sign-in', data);
const sign_in_social = (data) => Api().post('/v2/clients/sign-in-social', data);
const get_payment_methods = (beneficiaries) => Api().post('/v2/payments/payment-methods', beneficiaries);
const recovery_password = (email) => Api().post('/v1/clients/recovery', email);
const recovery_hash = (hash) =>
  Api().post('/v1/clients/recovery-verification', hash);
const update_password = (data) => Api().put('/v1/clients/update-pwd', data);
const send_email_afliation_continue = (id_titular) =>
  Api().get(`/v1/clients/send-email/${id_titular}`);
const verify_hash = (hash) =>
  Api().post('/v1/clients/verify-activation-hash', hash);
const active_account = (data) =>
  Api().post('v1/clients/activate-user-account', data);
const send_code_to_email = (email) =>
  Api().post('/v1/clients/send-code-to-email', email);
const send_voluntary_data = (data) =>
  Api().post('/v1/medical/volunteers/register', data);
const send_voluntary_code = (data) =>
  Api().post('/v1/medical/volunteers/verify', data);
const get_payment_methods_client = (data) =>
  Api().post('/v3/client/payment-methods', data);

const get_promoters = () => Api().get('/si/v1/promoter');
const create_promoter = (data) => Api().post('/si/v1/promoter', data);
const update_basic_promoter = (data) => Api().put('/si/v1/promoter', data);
const update_coupons_promoter = (data) =>
  Api().post('/si/v1/promoter/coupons', data);

// PROFILE
const get_titular_data = () => Api().get('/v2/clients/titular-data');
const get_beneficiaries_data = () =>
  Api().get('/v3/customers/beneficiaries-data');
const check_email_code = (data) =>
  Api().post('/v1/clients/check-email-code', data);
const get_titular_document = (options, data) =>
  Api(options).post('/v1/users/get-document-dni', data);
const update_titular = (data) => Api().put('/v1/clients/update-titular', data);
const change_password = (data) =>
  Api().put('/v1/clients/update-titular-pwd', data);
const update_photo = (data) => Api().put('/v1/clients/update-picture', data);
const update_beneficiary = (data) =>
  Api().put('/v1/clients/update-beneficiary', data);
const cancellation_request = (data) =>
  Api().post('/v1/cancellation-request', data);
const get_all_cancellation_request = () =>
  Api().get('/v1/cancellation-request');
const get_cancellation_request = (id) =>
  Api().get(`/v1/cancellation-request/${id}`);
const filter_cancellation_request = (data) =>
  Api().post('/v1/cancellation-request/search', data);
const update_cancellation_request = (data) =>
  Api().put('/v1/cancellation-request', data);
const find_clients = () => Api().get('/v1/clients');
const get_beneficiaries_titular = (id_titular) =>
  Api().get(`/v1/clients/${id_titular}`);
const search_client = (values) => Api().post('/v1/clients/search', values);
const search_beneficiaries = (values) =>
  Api().post('/si/v1/customers/search', values);
const validate_coupon = (values) =>
  Api().post('/si/v1/promoter/isValidCoupon', values);
const get_advanced_config_data = () => Api().get('/v1/clients/advanced-data');
const send_profile_documents = () =>
  Api().post('/v1/clients/send-affiliation-files');
const update_payment_info = (data) =>
  Api().put('/v1/clients/payment-info', data);
const annulled_free_trial = (data) =>
  Api().post('/v1/payment/annulled-free-trial', data);
// PROFILE - REPORT PAYMENTS
const list_payment_fees = (paymentID) =>
  Api().post('/v2/clients/interval-payment-fees', paymentID);
const report_payment_fees = (data) =>
  Api().post('/v2/clients/report-payment-fees', data);
const edit_reported_payment_fees = (data) =>
  Api().put('/v2/clients/edit-reported-fee', data);
//PROFILE - RENEW
const beneficiaries_to_renew = () =>
  Api().get('/v2/clients/beneficiaries-to-renew');
const beneficiaries_to_update = (paymentID) =>
  Api().post('/v2/clients/beneficiaries-rnw-subscription', paymentID);
const non_renewal_beneficiary = (beneficiaryID) =>
  Api().put('/v2/clients/non-renewal', beneficiaryID);
// const save_renewal_data = (data) => Api().post('/v2/clients/save-renewal-data', data);
const save_renewal_data = (data) =>
  Api().post('/v3/customers/save-renewal-beneficiaries', data);
const save_renewal_health_data = (data) =>
  Api().post('/v3/customers/save-renewal-health-data', data);
const payment_methods_renewal = (beneficiaries) =>
  Api().post('/v2/clients/payment-methods-renewal', beneficiaries);
const pay_renewal = (data) => Api().post('/v2/clients/pay-renewal', data);
const non_renewal_subscription = (data) =>
  Api().post('/v2/clients/non-renewal-subscription', data);
const auto_renew_subscription = (data) =>
  Api().post('/v2/clients/auto-pay-renewal', data);
const get_payment_bill = (options, paymentID) =>
  Api(options).post('/v2/payments/get-payment-bill/', paymentID);
const assignAddonRenew = (data) =>
  Api().post('/v2/addons/save-addons-beneficiary-renew', data);
// PROFILE - MEMBERSHIP
const create_new_member = (data) => Api().post('/v2/members', data);
//ALLYS
const get_allys = (date) => Api().get(`/v1/allies/my-allies/${date}`);
const get_afiliations = (date) => Api().get(`/v1/allies/afiliations/${date}`);
const invitation_ally = (values) => Api().post('/v1/allies', values);
const validate_ally_token = (value) =>
  Api().get(`/v1/allies/pre-activate/${value}`);
const activateAlly = (values) => Api().post('/v1/allies/activate', values);
const get_products_ally = () => Api().get('/v1/allies/products');
const get_allys_master = (matrix_id) =>
  Api().get(`/v1/allies/allies_master/master/${matrix_id}`);
const get_allys_matrix = () => Api().get('/v1/allies/allies_master/matriz');
const get_balance_ally_months = () => Api().get('/v1/allies/get-months-ally');
const get_balance_ally_data = (year, month) =>
  Api().get(`/v1/allies/allies-balance/${year}/${month}`);
const get_payments_ally = () => Api().get('/v1/allies/payment_data');
const request_withdrawal = (values) =>
  Api().post('/v1/allies/request-withdrawal', values);
const validate_shared_link = (values) =>
  Api().post('/v1/allies/validation_cookie', values);
const update_payment_methods_ally = (values) =>
  Api().put('/v1/allies/update-data-payment', values);
const update_shared_link = (values) =>
  Api().put('/v1/allies/link_rrss', values);
const resendemail = (values) => Api().post('/v1/allies/resendemail', values);
const get_all_ally_affiliations = ({ skip, limit, month, year }) =>
  Api().get(
    `/v3/allies/get-all-affiliations/?skip=${skip}&limit=${limit}&year=${year}&month=${month}`
  );
const get_all_ally_affiliations_export = ({ month, year }, options) =>
  Api(options).post(
    `/v3/allies/export-commissions/?skip=${null}&limit=${null}&year=${year}&month=${month}`
  );
const get_balance_point_ally = () => Api().get('/v1/allies/get-points');
const get_point_ally = (id) => Api().get(`v1/admin/allies/get-points/${id}`);
const get_ally_bigfamily_type = () => Api().get('/v2/allies/big-family/');
//ALLIES ADMIN
const get_all_allies = () => Api().get(`/v1/admin/allies`);
const get_ally_details = (id) => Api().get(`/v1/admin/allies/details/${id}`);
const get_ally_balance_monthly = (id) =>
  Api().get(`/v1/admin/allies/get-months-ally/${id}`);
const get_ally_balance_admin = (id, year, month) =>
  Api().get(`/v1/admin/allies/balance-ally-details/${id}/${year}/${month}`);
const get_allys_master_details = ({ id, date }) =>
  Api().post(`/v1/admin/allies/ally-references-details/${id}`, { date: date });
const get_affiliations_allies_details = ({ id, date }) =>
  Api().post(`/v1/admin/allies/ally-affiliations-details/${id}`, {
    date: date
  });
const get_allies_details = ({ id, date }) =>
  Api().post(`/v1/admin/allies/ally-references-details/${id}`, { date: date });
const search_allies = (values) => Api().post('/v1/admin/allies/search', values);
const updated_ally = (values, id) =>
  Api().put(`/v1/admin/allies/update-ally/${id}`, values);
const upload_masive_allies = (values) =>
  Api().post('/v1/admin/allies/csv-allies', values);
const resend_email_ally = (data) =>
  Api().post('/v1/admin/allies/resend_email_allies', data);

// SOS-19
const get_motives = () => Api().get('/v1/medical/questions/');
const get_medical_questions = (id) =>
  Api().get(`/v1/medical/questions/detail/${id}`);
const send_consult_data = (data) =>
  Api().post('/v1/medical/consultation', data);
const send_email = (data) =>
  Api().post('/v1/medical/consultation/send-email', data);
const send_code = (data) =>
  Api().post('/v1/medical/consultation/contact-code', data);

// COVID19
const covid19 = (data) => Api().post('/v2/covid-19', data);
const health_contact = (data) => Api().post('/v2/covid-19/contact', data);

// COMMISSIONS
const get_commissions = (val, id) =>
  Api().get(`/v1/admin/commission-scheme/${val || 0}/${id || ''}`);
const create_commissions = (data) =>
  Api().post('/v1/admin/commission-scheme', data);
const update_commissions = (data) =>
  Api().put('/v1/admin/commission-scheme', data);

// ALLY POINTS & CONDITIONS & AWARDS
const get_conditions = () => Api().get('/v1/admin/points');
const create_condition = (data) => Api().post('/v1/admin/points', data);
const update_condition = (id, data) =>
  Api().put(`/v1/admin/points/${id}`, data);
const find_condition = (data) => Api().post('/v1/admin/points/find', data);
const get_list_parameters = (type) =>
  Api().get(`/v1/admin/points/parameters/${type || 'comparison'}`);
const get_points = (id) => Api().get(`/v1/admin/points_allies/${id}`);
const create_point = (data) => Api().post('/v1/admin/points_allies', data);
const update_point = (id, data) =>
  Api().put(`/v1/admin/points_allies/${id}`, data);
const get_awards = () => Api().get('/v1/admin/awards');
const create_award = (data) => Api().post('/v1/admin/awards', data);
const update_award = (id, data) => Api().put(`/v1/admin/awards/${id}`, data);
const delete_award = (id) => Api().delete('/v1/admin/awards', { data: { id } });
const exchange_award = (id) => Api().get(`/v1/allies/request_prizes/${id}`);

//TYC'S
const get_legal_document = (data) =>
  Api().get(`/si/v2/legal-documents/document/${data}`);

 // CUSTOM DOWNLOAD
const custom_file_download = (options, data) => Api(options).post(`/v3/file-download`, data) 

// BIG BRODER
const get_big_broders = () => Api().get('/v2/admin/big-broder');
const get_big_broders_candidate = () =>
  Api().get('/v2/admin/big-broder/candidates');
const create_broder = (data) => Api().post('/v2/admin/big-broder', data);
const update_broder = (id, data) =>
  Api().put(`/v2/admin/big-broder/${id}`, data);
const delete_broder = (id) =>
  Api().delete('/v2/admin/big-broder', { data: id });
const assign_broder = (data) =>
  Api().post('/v2/admin/assign-big-broder/assign', data);
const assign_broder_random = (data) =>
  Api().post('/v2/admin/assign-big-broder/assign-random', data);
const update_assign_broder = (data) =>
  Api().put('/v2/admin/assign-big-broder/update-assign', data);
const get_ally_big_broder_admin = (idAlly) =>
  Api().get(`/v1/admin/allies/know-big-broder/${idAlly}`);
const get_ally_big_broder = () => Api().get('/v1/allies/know-my-big-broder');

// DASHBOARD
const get_policies_by_date = (start, end) =>
  Api().get(`/v2/sales/policies-by-date?start=${start}&end=${end}`);
const get_total_amounts_by_date = (start, end) =>
  Api().get(`/v2/sales/total-amounts-by-date?start=${start}&end=${end}`);
const get_plans_by_date = (start, end) =>
  Api().get(`/v2/sales/get-plans-by-date?start=${start}&end=${end}`);
const get_sales_profile = (start, end) =>
  Api().get(`/v2/sales/get-sales-profile?start=${start}&end=${end}`);
const get_sales_metrics = (data) =>
  Api().get(
    `/v2/sales/sales-projections/projection-metrics${
      data ? `?country=${data}` : ''
    }`
  );

// COUNTRY & REGION
const get_regions = () => Api().get('/v2/regions');
const create_region = (data) => Api().post('/v2/regions', data);
const update_region = (id, data) => Api().put(`/v2/regions/${id}`, data);
const get_countries = (data) => Api().get('/v2/countries', { params: data });
const massive_load_countries = (data) =>
  Api().post('/v2/countries/massive-load-countries', data);
const create_country = (data) => Api().post('/v2/countries', data);
const update_country = (id, data) => Api().put(`/v2/countries/${id}`, data);
const delete_country = (id) => Api().delete(`/v2/countries/${id}`);

// PROJECTIONS
const get_projections = () => Api().get('/v2/sales/sales-projections');
const create_projection = (data) =>
  Api().post('/v2/sales/sales-projections', data);
const update_projection = (id, data) =>
  Api().put(`/v2/sales/sales-projections/${id}`, data);
const delete_projection = (id) =>
  Api().delete(`/v2/sales/sales-projections/${id}`);

// VERIFICATIONS
const get_verifications = () =>
  Api().get('/si/v2/medical-evaluations/list-customers-verifications');
const upload_support = (id, data) =>
  Api().post(`/v2/clients/upload-medical-support/${id}`, data);
const evaluate_beneficiary = (id, data) =>
  Api().post(`/si/v2/medical-evaluations/evaluate/${id}`, data);

// CHANGE COUNTRY LOGIN
const generate_session_hash = () =>
  Api().get('/v2/clients/generate-session-hash');
const validate_session_hash = (data) =>
  Api().post('/v2/clients/verify-session-hash', data);

// GET LOCATION INFORMATION
const get_location_ip = () => Api().get('/v2/clients/getIp');

//ADVISER
const get_affiliations_details = (data) =>
  Api().post(`/v2/advisers/get-affiliation-details`, data);
const accept_terms_conditions = () =>
  Api().post(`/v2/clients/accept-term-conditions`);
const notify_adviser = (data) =>
  Api().post(`/v2/advisers/notify-adviser`, data);

// CURRENCY
const get_currecies_by_country = () =>
  Api().get('/v3/currencies/get-currencies-by-country');

// CJ
const set_cj_cookie = (data) => Api().post(`/v3/cj/set-cookie`, data);

export default {
  //TYC'S
  get_legal_document,
  payment_affiliation,
  save_data_customer,
  send_link_payment,
  plans,
  send_acunsa_code,
  validate,
  affiliations,
  assignAddon,
  save_data_customers,
  remove_temp_beneficiaries,
  customer_resume,
  save_health_data,
  leads,
  get_leads,
  export_lead,
  create_lead,
  update_lead,
  get_origin_filter,
  get_status_filter,
  get_active_promotions,
  user_login,
  find_all_users,
  find_by_email,
  user_update,
  create_user,
  delete_user,
  get_tdc_payments,
  get_overdue_payments,
  get_pending_payments,
  all_users_logs,
  get_companies,
  get_campaigns,
  get_validators,
  get_plans,
  get_surveys,
  get_addons,
  create_campaign,
  create_plan,
  create_addon,
  update_campaign,
  update_plan,
  update_addon,
  delete_addon,
  find_addon,
  find_addon_by_titular,
  save_addons_titular,
  find_all_addons,
  save_all_addons,
  validate_addon_answers,
  interested_validation,
  interested_data,
  data_customers,
  data_pending,
  search_customer,
  send_documentation,
  view_titular_registry,
  view_registry,
  annulled_subscription,
  get_renewal_subscriptions,
  update_registry,
  user_logs_search,
  export_data,
  getFilePayment,
  update_payment_fees,
  marketing,
  get_marketing,
  update_marketing,
  delete_marketing,
  view_marketing,
  export_campaign,
  get_medical_consults,
  get_consult_by_id,
  add_tracing,
  change_consultation_status,
  export_medical_consults,
  get_consults_alert,
  get_volunteers_by_specialty,
  get_all_volunteers,
  filter_volunteers,
  update_volunteers,
  get_data_attention,
  process_medical_consult,
  assignment_volunteer,
  filter_consultation,
  // ASISTENSI
  get_plans_by_country,
  products_quotation,
  shared_quotation,
  get_plans_by_age,
  products_by_frequency,
  create_validator,
  update_validator,
  get_products,
  sign_in,
  sign_in_social,
  sign_up,
  sign_up_social,
  get_payment_methods,
  get_payment_methods_client,
  create_payment_method,
  create_promotion,
  get_payment_methodsAll,
  get_promotions,
  update_payment_method,
  update_promotion,
  create_product,
  update_product,
  recovery_password,
  recovery_hash,
  update_password,
  send_email_afliation_continue,
  validate_coupon,
  send_voluntary_data,
  send_voluntary_code,
  get_promoters,
  create_promoter,
  update_basic_promoter,
  update_coupons_promoter,
  send_code_to_email,
  verify_hash,
  active_account,
  // PROFILE
  get_titular_data,
  get_beneficiaries_data,
  check_email_code,
  get_titular_document,
  update_titular,
  change_password,
  update_photo,
  update_beneficiary,
  cancellation_request,
  get_all_cancellation_request,
  get_cancellation_request,
  filter_cancellation_request,
  update_cancellation_request,
  find_clients,
  get_beneficiaries_titular,
  search_client,
  search_beneficiaries,
  get_proporsal_value,
  create_proposal_value,
  update_proposal_value,
  get_advanced_config_data,
  send_profile_documents,
  update_payment_info,
  annulled_free_trial,
  list_payment_fees,
  report_payment_fees,
  edit_reported_payment_fees,
  beneficiaries_to_renew,
  beneficiaries_to_update,
  non_renewal_beneficiary,
  save_renewal_data,
  payment_methods_renewal,
  pay_renewal,
  non_renewal_subscription,
  auto_renew_subscription,
  get_payment_bill,
  assignAddonRenew,
  save_renewal_health_data,
  // PROFILE - MEMBERSHIP
  create_new_member,
  //ALLYS
  get_allys,
  get_allys_master,
  get_allys_matrix,
  get_afiliations,
  invitation_ally,
  validate_ally_token,
  activateAlly,
  get_products_ally,
  get_balance_ally_months,
  get_balance_ally_data,
  get_payments_ally,
  request_withdrawal,
  update_payment_methods_ally,
  validate_shared_link,
  update_shared_link,
  resendemail,
  get_balance_point_ally,
  get_all_ally_affiliations,
  get_all_ally_affiliations_export,
  get_point_ally,
  get_ally_bigfamily_type,
  // ADMIN ALLIES
  get_all_allies,
  get_ally_details,
  get_ally_balance_monthly,
  get_ally_balance_admin,
  get_allys_master_details,
  get_affiliations_allies_details,
  get_allies_details,
  search_allies,
  updated_ally,
  upload_masive_allies,
  resend_email_ally,
  // SOS-19
  get_motives,
  get_medical_questions,
  send_consult_data,
  send_email,
  send_code,
  //COVID19
  covid19,
  health_contact,
  // COMMISSIONS
  get_commissions,
  create_commissions,
  update_commissions,
  // ALLY POINTS & CONDITIONS & AWARDS
  get_conditions,
  create_condition,
  update_condition,
  find_condition,
  get_list_parameters,
  get_points,
  create_point,
  update_point,
  get_awards,
  create_award,
  update_award,
  delete_award,
  exchange_award,
  // BIG BRODER
  get_big_broders,
  get_big_broders_candidate,
  create_broder,
  update_broder,
  delete_broder,
  assign_broder,
  assign_broder_random,
  update_assign_broder,
  get_ally_big_broder_admin,
  get_ally_big_broder,
  // DASHBOARD
  get_policies_by_date,
  get_total_amounts_by_date,
  get_plans_by_date,
  get_sales_profile,
  get_sales_metrics,
  // COUNTRY & REGION
  get_regions,
  create_region,
  update_region,
  get_countries,
  massive_load_countries,
  create_country,
  update_country,
  delete_country,
  // PROJECTIONS
  get_projections,
  create_projection,
  update_projection,
  delete_projection,
  // VERIFICATIONS
  get_verifications,
  upload_support,
  evaluate_beneficiary,
  // CHANGE COUNTRY LOGIN
  generate_session_hash,
  validate_session_hash,
  get_location_ip,
  //ADVISER
  get_affiliations_details,
  accept_terms_conditions,
  notify_adviser,
  // CURRENCY
  get_currecies_by_country,
  // CJ
  set_cj_cookie,
  //FILE DOWNLOAD
  custom_file_download
};
