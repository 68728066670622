import {
  GET_PRODUCTS,
  GET_PAYMENT_METHODS,
  GET_ADDONS,
  GET_VALIDATORS,
  GET_PROMOTIONS,
  GET_ACTIVE_PROMOTIONS,
  SEARCHROWSPERPAGE,
  GET_SURVEYS,
  GET_ALL_ADDONS,
} from '../actionTypes';
import Common from '../../src/services/common.js';

// PRODUCT
export const getAllProducts = () => {
  return async (dispatch) => {
    return await Common.get_products().then(async (r) => {
      await dispatch({
        type: GET_PRODUCTS,
        payload: r.data.products,
      });
      return { spinnerScreen: false };
    }).catch(e => {
      return {
        spinnerScreen: false,
        openAlert: true,
        messageAlert: e.response && e.response.data ?  e.response.data.error.message : 'Hubo un error al obtener los productos, intente nuevamente.',
      }
    });
  }
}

export const createProduct = (data) => {
  return async dispatch => {
    return Common.create_product(data).then(() => {
      return {
        success: true,
        spinnerScreen: false,
        showDialog: false,
        openAlert: true,
        messageAlert: 'Nuevo producto registrado exitosamente.',
      }
    }).catch((e) => ({
      spinnerScreen: false,
      openAlert: true,
      messageAlert: e.response && e.response.data ?  e.response.data.error.message : 'Hubo un error al crear el producto, intente nuevamente.',
    }))
  }
}

export const updateProduct = (data) => {
  return async dispatch => {
    return Common.update_product(data).then(() => {
      return {
        success: true,
        spinnerScreen: false,
        showDialog: false,
        openAlert: true,
        messageAlert: 'Producto actualizado exitosamente.',
      }
    }).catch((e) => {
      return {
        spinnerScreen: false,
        openAlert: true,
        messageAlert: e.response && e.response.data ?  e.response.data.error.message : 'Hubo un error al actualizar el producto, intente nuevamente.',
      }
    })
  }
}

export const getAllSurveys = () => {
  return async (dispatch) => {
    return await Common.get_surveys().then(async (r) => {
      await dispatch({
        type: GET_SURVEYS,
        payload: r.data.data,
      });
      return { spinnerScreen: false };
    }).catch(e => {
      return {
        spinnerScreen: false,
        openAlert: true,
        messageAlert: e.response && e.response.data ?  e.response.data.error.message : 'Hubo un error al obtener las encuestas, intente nuevamente.',
      }
    });
  }
}

// PAYMENT METHOD
export const getAllPaymentMethods = () => {
  return async (dispatch) => {
    return await Common.get_payment_methodsAll().then(async (r) => {
      await dispatch({
        type: GET_PAYMENT_METHODS,
        payload: r.data.payment_methods,
      });
      return { spinnerScreen: false };
    }).catch(e => {
      return {
        spinnerScreen: false,
        openAlert: true,
        messageAlert: e.response && e.response.data ?  e.response.data.error.message : 'Hubo un error al obtener los métodos de pago, intente nuevamente.',
      }
    });
  }
}

export const createPaymentMethod = (data) => {
  return async dispatch => {
    return Common.create_payment_method(data).then(() => {
      return {
        success: true,
        spinnerScreen: false,
        showDialog: false,
        openAlert: true,
        messageAlert: 'Nuevo método de pago registrado exitosamente.',
      }
    }).catch((e) => {
      return {
        spinnerScreen: false,
        openAlert: true,
        messageAlert: e.response && e.response.data ?  e.response.data.error.message : 'Hubo un error al crear el método de pago, intente nuevamente.',
      }
    })
  }
}

export const updatePaymentMethod = (data) => {
  return async dispatch => {
    return Common.update_payment_method(data).then(() => {
      return {
        success: true,
        spinnerScreen: false,
        showDialog: false,
        openAlert: true,
        messageAlert: 'Método de pago actualizado exitosamente.',
      }
    }).catch((e) => {
      return {
        spinnerScreen: false,
        openAlert: true,
        messageAlert: e.response && e.response.data ?  e.response.data.error.message : 'Hubo un error al actualizar el método de pago, intente nuevamente.',
      }
    })
  }
}

// ADDON
export const getAllAddons = () => {
  return async (dispatch) => {
    return await Common.get_addons().then(async (r) => {
      await dispatch({
        type: GET_ADDONS,
        payload: r.data.addons,
      });
      return { spinnerScreen: false };
    })
    .catch(e => {
      return {
        spinnerScreen: false,
        openAlert: true,
        messageAlert: e.response && e.response.data ?  e.response.data.error.message : 'Hubo un error al obtener los complementos, intente nuevamente.',
      }
    });
  }
}

export const createAddon = (data) => {
  return async dispatch => {
    return Common.create_addon(data).then(() => {
      return {
        success: true,
        spinnerScreen: false,
        showDialog: false,
        openAlert: true,
        messageAlert: 'Nuevo complemento registrado exitosamente.',
      }
    }).catch((e) => {
      return {
        spinnerScreen: false,
        openAlert: true,
        messageAlert: e.response && e.response.data ?  e.response.data.error.message : 'Hubo un error al crear el complemento, intente nuevamente.',
      }
    })
  }
}

export const updateAddon = (data) => {
  return async dispatch => {
    return Common.update_addon(data).then(() => {
      return {
        success: true,
        spinnerScreen: false,
        showDialog: false,
        openAlert: true,
        messageAlert: 'Complemento actualizado exitosamente.',
      }
    }).catch((e) => {
      return {
        success: false,
        spinnerScreen: false,
        openAlert: true,
        messageAlert: e.response && e.response.data ?  e.response.data.error.message : 'Hubo un error al actualizar el complemento, intente nuevamente.',
      }
    })
  }
}

export const deleteAddon = (addonId) => {
  return async dispatch => {
    return Common.delete_addon({ id: addonId }).then(() => {
      return {
        success: true,
        spinnerScreen: false,
        showDialog: false,
        openAlert: true,
        messageAlert: 'Complemento eliminado exitosamente.',
      }
    }).catch((e) => {
      return {
        success: false,
        spinnerScreen: false,
        openAlert: true,
        messageAlert: e.response && e.response.data ?  e.response.data.error.message : 'Hubo un error al eliminar el complemento, intente nuevamente.',
      }
    })
  }
}

export const findAddon = (data) => {
  return async dispatch => {
    return await Common.find_addon(data).then(async (r) => {
      return {
        success: true,
        addons: r.data.addons,
      };
    }).catch((e) => {
      return {
        success: false,
        openAlert: true,
        messageAlert: e.response && e.response.data ?  e.response.data.error.message : 'Hubo un error al eliminar el complemento, intente nuevamente.',
      }
    })
  }
}

export const findAddonByTitular = (data) => {
  return async dispatch => {
    return await Common.find_addon_by_titular(data).then(async (r) => {
      return {
        success: true,
        addons: r.data.addons,
      };
    }).catch((e) => {
      return {
        spinnerScreen: false,
        success: false,
        openAlert: true,
        messageAlert: e?.response?.data?.error?.message ? e.response.data.error.message : 'Hubo un error al obtener el complemento, intente nuevamente.',
      }
    })
  }
}

export const saveAddonTitular = (data) => {
  return async dispatch => {
    return await Common.save_addons_titular(data).then(async (r) => {
      return {
        success: true,
        addon: r.data.addon,
        addons_titular: r.data.addons_titular,
        created: r.data.created,
        payment_methods: r.data.payment_methods,
        promotion: r.data.promotion
      };
    }).catch((e) => {
      return {
        success: false,
        openAlert: true,
        messageAlert: e.response && e.response.data ?  e.response.data.error.message : 'Hubo un error al guardar las respuestas del formulario, intente nuevamente.',
      }
    })
  }
}


// VALIDATOR
export const getAllValidators = () => {
  return async (dispatch) => {
    return await Common.get_validators().then(async (r) => {
      await dispatch({
        type: GET_VALIDATORS,
        payload: r.data.validators,
      });
      return { spinnerScreen: false };
    })
    .catch(e => {
      return {
        spinnerScreen: false,
        openAlert: true,
        messageAlert: e.response && e.response.data ?  e.response.data.error.message : 'Hubo un error al obtener los validadores, intente nuevamente.',
      }
    });
  }
}

export const createValidator = (data) => {
  return async dispatch => {
    return Common.create_validator(data).then(() => {
      return {
        success: true,
        spinnerScreen: false,
        showDialog: false,
        openAlert: true,
        messageAlert: 'Nuevo validador registrado exitosamente.',
      }
    }).catch((e) => {
      return {
        spinnerScreen: false,
        openAlert: true,
        messageAlert: e.response && e.response.data ?  e.response.data.error.message : 'Hubo un error al crear el validador, intente nuevamente.',
      }
    })
  }
}

export const updateValidator = (data) => {
  return async dispatch => {
    return Common.update_validator(data).then(() => {
      return {
        success: true,
        spinnerScreen: false,
        showDialog: false,
        openAlert: true,
        messageAlert: 'Validador actualizado exitosamente.',
      }
    }).catch((e) => {
      return {
        spinnerScreen: false,
        openAlert: true,
        messageAlert: e.response && e.response.data ?  e.response.data.error.message : 'Hubo un error al actualizar el validador, intente nuevamente.',
      }
    })
  }
}

// PROMOTIONS
export const getAllPromotions = () => {
  return async (dispatch) => {
    return await Common.get_promotions()
    .then(async (r) => {
      await dispatch({
        type: GET_PROMOTIONS,
        payload: r.data.data,
      });
      return { spinnerScreen: false };
    })
    .catch(e => {
      return {
        spinnerScreen: false,
        openAlert: true,
        messageAlert: e.response && e.response.data && e.response.data.error ? e.response.data.error.message : 'Hubo un error al obtener las promociones, intente nuevamente.',
      }
    });
  }
}

export const createPromotion = (data) => {
  return async dispatch => {
    return Common.create_promotion(data)
    .then(() => {
      return {
        success: true,
        spinnerScreen: false,
        showDialog: false,
        openAlert: true,
        messageAlert: 'Nueva promoción registrada exitosamente.',
      }
    }).catch((e) => {
      return {
        spinnerScreen: false,
        openAlert: true,
        messageAlert: e.response && e.response.data && e.response.data.error ? e.response.data.error.message : 'Hubo un error al crear la promoción, intente nuevamente.',
      }
    })
  }
}

export const updatePromotion = (data) => {
  return async dispatch => {
    return Common.update_promotion(data)
    .then(() => {
      return {
        success: true,
        spinnerScreen: false,
        showDialog: false,
        openAlert: true,
        messageAlert: 'Promoción actualizada exitosamente.',
      }
    }).catch((e) => {
      return {
        spinnerScreen: false,
        openAlert: true,
        messageAlert: e.response && e.response.data ?  e.response.data.error.message : 'Hubo un error al actualizar la promoción, intente nuevamente.',
      }
    })
  }
}

export const getActivePromotions = () => {
  return async (dispatch) => {
    return await Common.get_active_promotions()
    .then(async (r) => {
      await dispatch({
        type: GET_ACTIVE_PROMOTIONS,
        payload: r.data.data,
      });
    })
  }
}

// new addons
export const findAllAddons = (data) => {
  return async dispatch => {
    return await Common.find_all_addons(data)
      .then(async (r) => {
        return {
          success: true,
          addons: r.data,
      };
    }).catch((e) => {
      return {
        success: false,
        openAlert: true,
        messageAlert: e.response && e.response.data ?  e.response.data.error.message : 'Hubo un error al consultar sus datos, intente nuevamente.',
      }
    })
  }
}

export const saveAllAddons = (data) => {
  return async dispatch => {
    return await Common.save_all_addons(data).then(async (r) => {
      return {
        success: true,
        addons: r.data
      };
    }).catch((e) => {
      return {
        success: false,
        openAlert: true,
        messageAlert: e.response && e.response.data ?  e.response.data.error.message : 'Hubo un error al guardar las respuestas del formulario, intente nuevamente.',
      }
    })
  }
}

export const validateAddonAnswers = (data) => {
  return async dispatch => {
    return await Common.validate_addon_answers(data).then(async (r) => {
      const { canPurchase, message } = r.data;

      return {
        canPurchase,
        message
      };
    }).catch((e) => {
      return {
        success: false,
        openAlert: true,
        messageAlert: e.response && e.response.data ?  e.response.data.error.message : 'Hubo un error al guardar las respuestas del formulario, intente nuevamente.',
      }
    })
  }
}