import axios from 'axios';
import Router from 'next/router';

const serverUrl = process.env.REACT_APP_SERVER_URL;

const API = (options) => {
  const instance = axios.create({
    headers: {
      "Authorization" : `Bearer ${localStorage.getItem('token')}`,
      "country_code": 'VE',
    },
    withCredentials: true,
    baseURL: `${serverUrl}/api/`,
    ...options
  });

  instance.interceptors.response.use(
    response => response,
    error => {
      if (error.response.status === 401 || error.response.status === 403) {
        localStorage.clear();
        Router.push('/authentication/signIn', '/iniciar-sesion');
      }

      if (
        error.request.responseType === 'blob' &&
        error.response.data instanceof Blob &&
        error.response.data.type &&
        error.response.data.type.toLowerCase().indexOf('json') != -1
      )
      {
        return new Promise((resolve, reject) => {
          let reader = new FileReader();
          reader.onload = () => {
            error.response.data = JSON.parse(reader.result);
            resolve(Promise.reject(error));
          };

          reader.onerror = () => {
            reject(error);
          };

          reader.readAsText(error.response.data);
        });
      };

      return Promise.reject(error);
    }
  );

  return instance;
}

export default API;
