import {
  SET_PROJECTION,
  SET_PROJECTIONS,
} from '../actionTypes';
import Common from '../../src/services/common.js';

export const getAllProjections = () => {
  return async dispatch => {
    return Common.get_projections().then(async (r) => {
      await dispatch({
        type: SET_PROJECTIONS,
        payload: r.data.sales_projections,
      });
      return { spinnerScreen: false };
    }).catch(e => {
      return {
        spinnerScreen: false,
        openAlert: true,
        messageAlert: e.response && e.response.data ?  e.response.data.error.message : 'Hubo un error al obtener las proyecciones, intente nuevamente.',
      }
    });
  };
};

export const createProjection = (data) => {
  return async dispatch => {
    return Common.create_projection(data).then(() => {
      return {
        success: true,
        spinnerScreen: false,
        showDialog: false,
        openAlert: true,
        messageAlert: 'Nueva proyección registrada exitosamente.',
      }
    }).catch((e) => {
      return {
        spinnerScreen: false,
        openAlert: true,
        messageAlert: e.response && e.response.data ? e.response.data.error.message : 'Hubo un error al crear la proyección, intente nuevamente.',
      }
    })
  }
}

export const updateProjection = (id, data) => {
  return async dispatch => {
    return Common.update_projection(id, data).then(() => {
      return {
        success: true,
        spinnerScreen: false,
        showDialog: false,
        openAlert: true,
        messageAlert: 'Proyección actualizada exitosamente.',
      }
    }).catch((e) => {
      return {
        spinnerScreen: false,
        openAlert: true,
        messageAlert: e.response && e.response.data ?  e.response.data.error.message : 'Hubo un error al actualizar la proyección, intente nuevamente.',
      }
    })
  }
}

export const deleteProjection = (data) => {
  return async dispatch => {
    return Common.delete_projection(data).then(() => {
      return {
        success: true,
        spinnerScreen: false,
        showConfirm: false,
        openAlert: true,
        messageAlert: 'Proyección eliminada exitosamente.',
      }
    }).catch((e) => {
      return {
        spinnerScreen: false,
        openAlert: true,
        messageAlert: e.response && e.response.data ?  e.response.data.error.message : 'Hubo un error al eliminar la proyección, intente nuevamente.',
      }
    })
  }
}

export const setProjectionData = (data) => {
  return async dispatch => {
    return await dispatch({
      type: SET_PROJECTION,
      payload: data,
    })
  }
}
