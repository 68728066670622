import {
  SET_CURRENCY,
  SET_ALL_CURRENCIES,
} from '../actionTypes';
import Common from '../../src/services/common';

// CURRENCY
export const setCurrency = (currency) => dispatch => dispatch({
  type: SET_CURRENCY,
  payload: {
    values: {
      currency
    }
  }
});

export const getCurrenciesByCountry = () => {
  return async dispatch => {
    return await Common.get_currecies_by_country()
      .then(async res => {
        await dispatch({
          type: SET_ALL_CURRENCIES,
          payload: {
            values: res?.data?.currencies,
          }
        });
        return {
          currencies: res?.data?.currencies
        }
      })
      .catch(async err => {
        return {
          success: false,
          openAlert: true,
          messageAlert: err?.response?.data?.error?.message ? err.response.data.error.message : 'Hubo un error al obtener las divisas disponibles, intente nuevamente',
        };
      })
  }
}
