import {
  SET_POINTS,
  SET_POINT,
  SET_LIST_PARAMETERS,
} from '../actionTypes';
import Common from '../../src/services/common.js';

export const getAllPoints = (id) => {
  return async dispatch => {
    return Common.get_points(id).then(async (r) => {
      await dispatch({
        type: SET_POINTS,
        payload: r.data.points,
      });
      return { spinnerScreen: false };
    }).catch(e => {
      return {
        spinnerScreen: false,
        openAlert: true,
        messageAlert: e.response && e.response.data ?  e.response.data.error.message : 'Hubo un error al obtener los puntos, intente nuevamente.',
      }
    });
  };
};

export const createPoint = (data) => {
  return async dispatch => {
    return Common.create_point(data).then(() => {
      return {
        success: true,
        spinnerScreen: false,
        showDialog: false,
        openAlert: true,
        messageAlert: 'Nuevo punto registrado exitosamente.',
      }
    }).catch((e) => {
      return {
        spinnerScreen: false,
        openAlert: true,
        messageAlert: e.response && e.response.data ? e.response.data.error.message : 'Hubo un error al crear el punto, intente nuevamente.',
      }
    })
  }
}

export const updatePoint = (id, data) => {
  return async dispatch => {
    return Common.update_point(id, data).then(() => {
      return {
        success: true,
        spinnerScreen: false,
        showDialog: false,
        openAlert: true,
        messageAlert: 'Punto actualizado exitosamente.',
      }
    }).catch((e) => {
      return {
        spinnerScreen: false,
        openAlert: true,
        messageAlert: e.response && e.response.data ?  e.response.data.error.message : 'Hubo un error al actualizar el punto, intente nuevamente.',
      }
    })
  }
}

export const setPointData = (data) => {
  return async dispatch => {
    return await dispatch({
      type: SET_POINT,
      payload: data,
    })
  }
}
