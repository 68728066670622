const validateFieldHeadLine = (titular, saveState, field, value) => {
  let formErrors = { ...titular.formErrors };
  let validate = false;
  switch (field) {
    case "first_name":
      validate = /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ]{3,})$/.test(value);
      !validate
        ? (formErrors.first_name = "Nombres inválidos.")
        : delete formErrors.first_name;
      break;
    case "last_name":
      validate = /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ]{3,})$/.test(value);
      !validate
        ? (formErrors.last_name = "Apellidos inválidos.")
        : delete formErrors.last_name;
      break;
    case "nationality":
      validate =
        /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ]{3,})$/.test(value) && value !== "default";
      !validate
        ? (formErrors.nationality = "Debe seleccionar el tipo de documento.")
        : delete formErrors.nationality;
      break;
    case "dni":
      validate = /^([0-9a-zA-Z]{3,20})$/.test(value);
      !validate
        ? (formErrors.dni = "Debe colocar un número de documento válido.")
        : delete formErrors.dni;
      break;
    case "doc_dni":
      const type = value && value.type ? value.type : null;
      !(
        type === "image/jpeg" ||
        type === "image/jpg" ||
        type === "image/png" ||
        type === "application/pdf"
      ) || type === null
        ? (formErrors.doc_dni =
            "Debe adjuntar un documento de identidad válido.")
        : delete formErrors.doc_dni;
      break;
    case "sex":
      validate = /^([FM]{1})$/.test(value) && value !== "default";
      !validate
        ? (formErrors.sex = "Debe seleccionar el sexo.")
        : delete formErrors.sex;
      break;
    case "birth_date":
      let date = value ? new Date(value) : null;
      let isDate = isValidDate(date);
      !isDate || date > new Date()
        ? (formErrors.birth_date = "Fecha ingresada inválida.")
        : delete formErrors.birth_date;
      break;
    case "email":
      validate = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(value);
      !validate
        ? (formErrors.email = "Correo electrónico inválido.")
        : delete formErrors.email;
      break;
    case "phone_one":
      validate = /^([0-9()+ -]{10,})$/.test(value);
      !validate
        ? (formErrors.phone_one =
            "Teléfono inválido. Ejemplo: (+58) 416 765-432")
        : delete formErrors.phone_one;
      break;
    case "country":
      validate = value !== "" && value !== "default";
      !validate
        ? (formErrors.country = "Debe seleccionar un país válido.")
        : delete formErrors.country;
      break;
    case "product_id":
      validate = value !== null && value !== undefined && value !== "";
      !validate
        ? (formErrors.product_id = "Debe seleccionar un plan de salud.")
        : delete formErrors.product_id;
      break;
    case "heigth":
      validate = /^([0-9]+(\.[0-9]?[0-9]))$/.test(value) && value <= 3;
      !validate
        ? (formErrors.heigth =
            "Debe ingresar su estatura con al menos un decimal")
        : delete formErrors.heigth;
      break;
    case "weight":
      validate = /^([0-9]{1,6})$/.test(value) && value <= 300;
      !validate
        ? (formErrors.weight = "Debe ingresar el peso. Ejemplo: 60")
        : delete formErrors.weight;
      break;
    case "city":
      validate =
        /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ,.-]{2,})$/.test(value) && value !== "default";
      !validate
        ? (formErrors.city = "Debe ingresar una ciudad válida.")
        : delete formErrors.city;
      break;
    case "address":
      validate = /^([a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ #,.-]{5,})$/.test(value);
      !validate
        ? (formErrors.address = "Debe ingresar una dirección válida.")
        : delete formErrors.address;
      break;
    case "code":
      validate = /^[0-9]*$/.test(value);
      !validate
        ? (formErrors.code = "Código inválido, debe ser numérico.")
        : delete formErrors.code;
      break;
    default:
      break;
  }

  titular.formErrors = formErrors;
  saveState("titular", titular);
};

const validateFieldHeadLineRequired = (titular, saveState, field, value) => {
  let formErrors = titular.formErrors;

  let validate = false;
  switch (field) {
    case "first_name":
      validate = /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ]{3,})$/.test(value);
      !validate
        ? (formErrors.first_name = "Nombres inválidos.")
        : delete formErrors.first_name;
      break;
    case "last_name":
      validate = /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ]{3,})$/.test(value);
      !validate
        ? (formErrors.last_name = "Apellidos inválidos.")
        : delete formErrors.last_name;
      break;
    case "nationality":
      validate =
        /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ]{3,})$/.test(value) && value !== "default";
      !validate
        ? (formErrors.nationality = "Debe seleccionar el tipo de documento.")
        : delete formErrors.nationality;
      break;
    case "dni":
      validate = /^([0-9a-zA-Z]{3,20})$/.test(value);
      !validate
        ? (formErrors.dni = "Debe colocar un número de documento válido.")
        : delete formErrors.dni;
      break;
    case "doc_dni":
      const type = value && value.type ? value.type : null;
      !(
        type === "image/jpeg" ||
        type === "image/jpg" ||
        type === "image/png" ||
        type === "application/pdf"
      ) || type === null
        ? (formErrors.doc_dni =
            "Debe adjuntar un documento de identidad válido.")
        : delete formErrors.doc_dni;
      break;
    case "sex":
      validate = /^([FM]{1})$/.test(value) && value !== "default";
      !validate
        ? (formErrors.sex = "Debe seleccionar el sexo.")
        : delete formErrors.sex;
      break;
    case "birth_date":
      let date = value ? new Date(value) : null;
      let isDate = isValidDate(date);
      !isDate || date > new Date()
        ? (formErrors.birth_date = "Fecha ingresada inválida.")
        : delete formErrors.birth_date;
      break;
    case "email":
      validate = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(value);
      !validate
        ? (formErrors.email = "Correo electrónico inválido.")
        : delete formErrors.email;
      break;
    case "phone_one":
      validate = /^([0-9()+ -]{10,})$/.test(value);
      !validate
        ? (formErrors.phone_one =
            "Teléfono inválido. Ejemplo: (+58) 416 765-432")
        : delete formErrors.phone_one;
      break;
    case "country":
      validate = value !== "" && value !== "default";
      !validate
        ? (formErrors.country = "Debe seleccionar un país válido.")
        : delete formErrors.country;
      break;
    case "city":
      validate =
        /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ,.-]{2,})$/.test(value) && value !== "default";
      !validate
        ? (formErrors.city = "Debe ingresar una ciudad válida.")
        : delete formErrors.city;
      break;
    case "address":
      validate = /^([a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ #,.-]{5,})$/.test(value);
      !validate
        ? (formErrors.address = "Debe ingresar una dirección válida.")
        : delete formErrors.address;
      break;

    default:
      break;
  }

  titular.formErrors = formErrors;
  saveState("titular", titular);
};

const validateTitularForm = (titular, saveState, field, value) => {
  let formErrors = titular.formErrors;
  let validate = false;
  switch (field) {
    case "first_name":
      validate = /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ]{3,})$/.test(value);
      !validate
        ? (formErrors.first_name = "Nombres inválidos.")
        : delete formErrors.first_name;
      break;
    case "last_name":
      validate = /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ]{3,})$/.test(value);
      !validate
        ? (formErrors.last_name = "Apellidos inválidos.")
        : delete formErrors.last_name;
      break;
    case "email":
      validate = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(value);
      !validate
        ? (formErrors.email = "Correo electrónico inválido.")
        : delete formErrors.email;
      break;
    case "phone_one":
      validate = /^([0-9()+ -]{10,})$/.test(value);
      !validate
        ? (formErrors.phone_one =
            "Teléfono inválido. Ejemplo: (+58) 416 765-432")
        : delete formErrors.phone_one;
      break;
    case "agree_policy_privacy":
      validate = value === true ? true : false;
      !validate
        ? (formErrors.agree_policy_privacy =
            "Debe aceptar las términos y condiciones")
        : delete formErrors.agree_policy_privacy;
      break;
    case "password":
      validate =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@_#\$%\^&\*\.\+\-])(?=.{8,20})/.test(
          value
        );

      !validate
        ? (formErrors.password =
            "La contraseña debe tener mínimo de 8 y un máximo 20 carácteres, al menos una mayúscula, un cáracter especial y al menos un número.")
        : delete formErrors.password;
      if (titular?.password && !formErrors.password) {
        value === titular?.password_confirm
          ? delete formErrors.password_confirm
          : (formErrors.password_confirm = "La contraseña no coincide.");
      }
      break;
    case "password_confirm":
      validate = value === titular?.password;
      !validate
        ? (formErrors.password_confirm = "La contraseña no coincide.")
        : delete formErrors.password_confirm;
      break;
    default:
      break;
  }
  titular.formErrors = formErrors;
  saveState("titular", titular);
};

const validateFieldBeneficiaries = (
  beneficiaries,
  saveState,
  field,
  value,
  index
) => {
  let formErrors = beneficiaries[index].formErrors || {};
  let validate = false;
  switch (field) {
    case "kinship":
      validate =
        /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ]{1,})$/.test(value) && value !== "default";
      !validate
        ? (formErrors.kinship = "Debe seleccionar el parentesco.")
        : delete formErrors.kinship;
      break;
    case "other_kinship":
      validate =
        beneficiaries[index].kinship === "Otro"
          ? /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ]{3,})$/.test(value)
          : value === "";
      !validate
        ? (formErrors.other_kinship = "Debe especificar el parentesco.")
        : delete formErrors.other_kinship;
      break;
    case "first_name":
      validate = /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ]{3,})$/.test(value);
      !validate
        ? (formErrors.first_name = "Nombres inválidos.")
        : delete formErrors.first_name;
      break;
    case "last_name":
      validate = /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ]{3,})$/.test(value);
      !validate
        ? (formErrors.last_name = "Apellidos inválidos.")
        : delete formErrors.last_name;
      break;
    case "nationality":
      validate =
        /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ]{3,})$/.test(value) && value !== "default";
      !validate
        ? (formErrors.nationality = "Debe seleccionar el tipo de documento.")
        : delete formErrors.nationality;
      break;
    case "dni":
      validate = /^([0-9a-zA-Z]{3,20})$/.test(value);
      !validate
        ? (formErrors.dni = "Debe colocar un número de documento válido.")
        : delete formErrors.dni;
      break;
    case "sex":
      validate = /^([FM]{1})$/.test(value) && value !== "default";
      !validate
        ? (formErrors.sex = "Debe seleccionar el sexo.")
        : delete formErrors.sex;
      break;
    case "birth_date":
      let date = value ? new Date(value) : null;
      let isDate = isValidDate(date);
      !isDate || date > new Date()
        ? (formErrors.birth_date = "Fecha ingresada inválida.")
        : delete formErrors.birth_date;
      break;
    case "email":
      validate = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(value);
      !validate
        ? (formErrors.email = "Correo electrónico inválido.")
        : delete formErrors.email;
      break;
    case "phone_one":
      validate = /^([0-9()+ -]{10,})$/.test(value);
      !validate
        ? (formErrors.phone_one =
            "Teléfono inválido. Ejemplo: (+58) 416 765-432")
        : delete formErrors.phone_one;
      break;
    case "product_id":
      validate = value !== null && value !== undefined && value !== "";
      !validate
        ? (formErrors.product_id = "Debe seleccionar un plan de salud.")
        : delete formErrors.product_id;
      break;
    case "heigth":
      validate = /^([0-9]+(\.[0-9]?[0-9]))$/.test(value) && value <= 3;
      !validate
        ? (formErrors.heigth =
            "Debe ingresar su estatura con al menos un decimal")
        : delete formErrors.heigth;
      break;
    case "weight":
      validate = /^([0-9]{1,6})$/.test(value) && value <= 300;
      !validate
        ? (formErrors.weight = "Debe ingresar el peso. Ejemplo: 60")
        : delete formErrors.weight;
      break;
    case "country":
      validate = value !== "" && value !== "default";
      !validate
        ? (formErrors.country = "Debe seleccionar un país válido.")
        : delete formErrors.country;
      break;
    case "city":
      validate =
        /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ,.-]{2,})$/.test(value) && value !== "default";
      !validate
        ? (formErrors.city = "Debe ingresar una ciudad válida.")
        : delete formErrors.city;
      break;
    case "address":
      validate = /^([a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ #,.-]{5,})$/.test(value);
      !validate
        ? (formErrors.address = "Debe ingresar una dirección válida.")
        : delete formErrors.address;
      break;
    default:
      break;
  }
  beneficiaries[index].formErrors = formErrors;
  saveState("beneficiaries", beneficiaries);
};

const validatePaymentForm = (payment, saveState, field, value) => {
  let formErrors = payment.formErrors;
  let validate = false;
  switch (field) {
    case "first_name":
      validate = /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ,.-]{3,})$/.test(value);
      !validate
        ? (formErrors.first_name = "Nombres inválidos.")
        : delete formErrors.first_name;
      break;
    case "last_name":
      validate = /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ,.-]{3,})$/.test(value);
      !validate
        ? (formErrors.last_name = "Apellidos inválidos.")
        : delete formErrors.last_name;
      break;
    case "dni":
      validate = /^([a-zA-Z0-9]{3,20})$/.test(value);
      !validate
        ? (formErrors.dni = "Debe colocar una número de documento válido.")
        : delete formErrors.dni;
      break;
    case "email":
      validate = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(value);
      !validate
        ? (formErrors.email = "Correo electrónico inválido.")
        : delete formErrors.email;
      break;
    case "phone_one":
      validate = /^([0-9()+ -]{10,})$/.test(value);
      !validate
        ? (formErrors.phone_one =
            "Teléfono inválido. Ejemplo: (+58) 416 765-4321")
        : delete formErrors.phone_one;
      break;
    case "zip_zone":
      validate = /^([0-9]{3,})$/.test(value);
      !validate
        ? (formErrors.zip_zone = "Debe ingresar una zona postal válida.")
        : delete formErrors.zip_zone;
      break;
    case "country":
      validate =
        /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ,.-]{2,})$/.test(value) && value !== "default";
      !validate
        ? (formErrors.country = "Debe ingresar un país válido.")
        : delete formErrors.country;
      break;
    case "state":
      validate = /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ,.-]{2,})$/.test(value);
      !validate
        ? (formErrors.state = "Debe ingresar un estado válido.")
        : delete formErrors.state;
      break;
    case "city":
      validate = /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ,.-]{2,})$/.test(value);
      !validate
        ? (formErrors.city = "Debe ingresar una ciudad válida.")
        : delete formErrors.city;
      break;
    case "acceptTerms":
      validate = value === true;
      !validate
        ? (formErrors.acceptTerms = "Debe aceptar los términos y condiciones.")
        : delete formErrors.acceptTerms;
      break;
    case 'DIP':
      validate = value === true;
      (!validate) ? formErrors.DIP = "Debe aceptar el Documento de Información Previa (IPID) y el Documento de Información del Mediador." : delete formErrors.DIP;
      break;
    case "oath_statement":
      validate = value === true;
      !validate
        ? (formErrors.oath_statement =
            "Debe declarar que la información suministrada es exacta y verídica.")
        : delete formErrors.oath_statement;
      break;
    case "payment_interval":
      validate =
        /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ,.-]{1,})$/.test(value) && value !== "default";
      !validate
        ? (formErrors.payment_interval =
            "Debe seleccionar una frecuencia de pago.")
        : delete formErrors.payment_interval;
      break;
    case "payment_method":
      validate = /^([ECZSBTP]{1})$/.test(value) && value !== "default";
      !validate
        ? (formErrors.payment_method = "Debe seleccionar una forma de pago.")
        : delete formErrors.payment_method;
      break;
    case "payment_voucher":
      const type = value && value.type ? value.type : null;
      payment.payment_method === "Z" || payment.payment_method === "T"
        ? !(
            type === "image/jpeg" ||
            type === "image/jpg" ||
            type === "image/png" ||
            type === "application/pdf"
          )
          ? (formErrors.payment_voucher =
              "Debe seleccionar un archivo válido (.jpeg, .jpg, .png, .pdf)")
          : delete formErrors.payment_voucher
        : delete formErrors.payment_voucher;
      break;
    case "reference_number":
      validate =
        payment.payment_method === "T" ||
        payment.payment_method === "Z" ||
        payment.payment_method === "P"
          ? /^([a-zA-Z0-9]{3,})$/.test(value)
          : true;
      !validate
        ? (formErrors.reference_number =
            "Debe ingresar un número de referencia válido.")
        : delete formErrors.reference_number;
      break;
    case "name":
      validate =
        payment.payment_method === "S"
          ? /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ,.-]{3,})$/.test(value)
          : true;
      !validate
        ? (formErrors.name =
            "Debe ingresar un nombre de propietario de la tarjeta válido.")
        : delete formErrors.name;
      break;
    case "number":
      validate =
        payment.payment_method === "S" ? /^([0-9]{14,16})$/.test(value) : true;
      !validate
        ? (formErrors.number = "Debe ingresar un número de tajeta válido.")
        : delete formErrors.number;
      break;
    case "exp":
      validate =
        payment.payment_method === "S" ? /^([0-9//]{5})$/.test(value) : true;
      !validate
        ? (formErrors.exp = "Debe ingresar una fecha de expiración válida.")
        : delete formErrors.exp;
      break;
    case "cvc":
      validate =
        payment.payment_method === "S" ? /^([0-9]{3,4})$/.test(value) : true;
      !validate
        ? (formErrors.cvc = "Debe ingresar un número de CVC válido.")
        : delete formErrors.cvc;
      break;
    default:
      break;
  }
  payment.formErrors = formErrors;
  saveState({ payment });
};

const validateFieldBeneficiariesRequired = (
  beneficiaries,
  saveState,
  field,
  value,
  index
) => {
  let formErrors = beneficiaries[index].formErrors;
  let validate = false;
  switch (field) {
    case "first_name":
      validate = /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ]{3,})$/.test(value);
      !validate
        ? (formErrors.first_name = "Nombres inválidos.")
        : delete formErrors.first_name;
      break;
    case "last_name":
      validate = /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ]{3,})$/.test(value);
      !validate
        ? (formErrors.last_name = "Apellidos inválidos.")
        : delete formErrors.last_name;
      break;
    case "nationality":
      validate = /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ]{3,})$/.test(value);
      !validate
        ? (formErrors.nationality = "Debe seleccionar el tipo de documento.")
        : delete formErrors.nationality;
      break;
    case "dni":
      validate = /^([0-9a-zA-Z]{3,20})$/.test(value);
      !validate
        ? (formErrors.dni = "Debe colocar un número de documento válido.")
        : delete formErrors.dni;
      break;
    case "birth_date":
      let date = value ? new Date(value) : null;
      let isDate = isValidDate(date);
      !isDate || date > new Date()
        ? (formErrors.birth_date = "Fecha ingresada inválida.")
        : delete formErrors.birth_date;
      break;
    default:
      break;
  }
  beneficiaries[index].formErrors = formErrors;
  saveState("beneficiaries", beneficiaries);
};

const validateFieldContact = (contact, saveState, field, value) => {
  let formErrors = contact.formErrors;
  let validate = false;
  switch (field) {
    case "name":
      validate = /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ]{3,})$/.test(value);
      !validate ? (formErrors.name = "Invalid names.") : delete formErrors.name;
      break;
    case "dni":
      validate = /^([0-9a-zA-Z]{3,20})$/.test(value);
      !validate
        ? (formErrors.dni = "Must enter a valid document number.")
        : delete formErrors.dni;
      break;
    case "phone":
      validate = /^([0-9()+ -]{10,})$/.test(value);
      !validate
        ? (formErrors.phone = "Invalid phone. Example: (+58) 416 765-432")
        : delete formErrors.phone;
      break;
    case "email":
      validate = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(value);
      !validate
        ? (formErrors.email = "Invalid email address.")
        : delete formErrors.email;
      break;
    case "message":
      validate = /^([a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ ,.-¿?']{15,})$/.test(value);
      !validate
        ? (formErrors.message = "Must enter a valid message.")
        : delete formErrors.message;
      break;
    default:
      break;
  }
  contact.formErrors = formErrors;
  saveState({ contact });
};

const validFieldInitAffiliation = (formErrors, field, value) => {
  let validate = false;
  switch (field) {
    case "dni":
      validate = /^([0-9a-zA-Z]{5,20})$/.test(value);
      !validate
        ? (formErrors.dni = "Debe introducir un número de documento válido.")
        : delete formErrors.dni;
      break;
    case "email":
      validate = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(value);
      !validate
        ? (formErrors.email = "Correo electrónico inválido.")
        : delete formErrors.email;
      break;
    default:
      break;
  }
  return formErrors;
};

const isValidDate = (date) => {
  return date instanceof Date && !isNaN(date);
};

const validateFieldsProduct = (formErrors, field, value) => {
  let validate = false;

  switch (field) {
    case "termsFiles":
      const type = value && value.type ? value.type : null;
      type && type !== "application/pdf"
        ? (formErrors.termsFiles = "Archivo inválido, solo archivos .pdf")
        : delete formErrors.termsFiles;
      break;
    case "logo":
      const types = ["image/jpeg", "image/jpg", "image/png"];
      const typeLogo = value && value.type ? value.type : null;
      typeLogo && !types.includes(typeLogo)
        ? (formErrors.logo = "Archivo inválido, solo archivos .png .jpg .jpeg")
        : delete formErrors.logo;
      break;
    case "name":
      validate = /^([a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ,.:;!@#$?¿%^&*()-_ ]{1,})$/.test(
        value
      );
      !validate
        ? (formErrors.name = "Nombre inválido.")
        : delete formErrors.name;
      break;
    case "survey":
      validate = value !== "default";
      !validate
        ? (formErrors.survey = "Nombre inválido.")
        : delete formErrors.survey;
      break;
    case "not":
      validate = /^([a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ,.:;!@#$?¿%^&*()-_  ]{2,})$/.test(
        value
      );
      !validate
        ? (formErrors.no = "Respuesta invalida.")
        : delete formErrors.no;
      break;
    case "yes":
      validate = /^([a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ,.:;!@#$?¿%^&*()-_  ]{2,})$/.test(
        value
      );
      !validate
        ? (formErrors.yes = "Respuesta Invalida.")
        : delete formErrors.yes;
      break;
    case "sex":
      validate = /^([a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ,.:;!@#$?¿%^&*()-_  ]{1,})$/.test(
        value
      );
      !validate ? (formErrors.sex = "Sexo Invalido.") : delete formErrors.sex;
      break;
    case "help":
      validate = /^([a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ,.:;!@#$?¿%^&*()-_  ]{3,})$/.test(
        value
      );
      !validate
        ? (formErrors.help = "Ayuda inválida.")
        : delete formErrors.help;
      break;
    case "value":
      validate = /^([a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ,.:;!@#$?¿%^&*()-_  ]{3,})$/.test(
        value
      );
      !validate
        ? (formErrors.value = "Valor inválido.")
        : delete formErrors.value;
      break;
    case "shortName":
      validate = /^([A-ZÑÁÉÍÓÚüÜ,.:;!@#$?¿%^&*()-_ ]{1,})$/.test(value);
      !validate
        ? (formErrors.shortName =
            "Abreviación inválida, solo letras mayúsculas.")
        : delete formErrors.shortName;
      break;
    case "countryCode":
      validate = /^([A-ZÑÁÉÍÓÚüÜ ]{1,})$/.test(value);
      !validate
        ? (formErrors.countryCode = "Codigo Invalido, solo letras mayúsculas.")
        : delete formErrors.countryCode;
      break;
    case "recurrent_interval_base":
      validate = /^([a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ,.:;!@#$?¿%^&*()-_  ]{1,})$/.test(
        value
      );
      !validate
        ? (formErrors.recurrent_interval_base = "Intervalo inválido.")
        : delete formErrors.recurrent_interval_base;
      break;
    case "country":
      validate = /^([a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ,.:;!@#$?¿%^&*()-_  ]{3,})$/.test(
        value
      );
      !validate
        ? (formErrors.country = "Pais inválido.")
        : delete formErrors.country;
      break;
    case "price_base":
      validate = /^([0-9]{1,})$/.test(value) && value > 0;
      !validate
        ? (formErrors.price_base = "Precio invalido.")
        : delete formErrors.price_base;
      break;
    default:
      break;
  }
  return { formErrors };
};

const validateOptionsProduct = (
  formErrors,
  formErrorsOptions,
  field,
  value,
  index
) => {
  let validate = false;

  switch (field) {
    case "name":
      validate = /^([a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ,.:;!@#$?¿%^&*()-_  ]{1,})$/.test(
        value
      );
      if (!validate) {
        formErrorsOptions.name[index] = "Descripcion invalida";
        formErrors.nameSpecial = "Descripcion invalida";
      } else {
        formErrorsOptions.name && formErrorsOptions.name.splice(index, 1);
        delete formErrors.nameSpecial;
      }
      break;
    case "value":
      validate = /^([a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ,.:;!@#$?¿%^&*()-_  ]{1,})$/.test(
        value
      );
      if (!validate) {
        formErrorsOptions.value[index] = "Valor invalido";
        formErrors.valueoptions = "Valor invalido";
      } else {
        formErrorsOptions.value && formErrorsOptions.value.splice(index, 1);
        delete formErrors.valueoptions;
      }
      break;

    default:
      break;
  }
  return { formErrors, formErrorsOptions };
};

const validateServicesProduct = (
  formErrors,
  formErrorsServices,
  field,
  value,
  index
) => {
  let validate = false;

  switch (field) {
    case "name":
      validate = /^([a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ,.:;!@#$?¿%^&*()-_  ]{3,})$/.test(
        value
      );
      if (!validate) {
        formErrorsServices.name[index] = "Descripcion invalida";
        formErrors.nameSpecial = "Descripcion invalida";
      } else {
        formErrorsServices.name && formErrorsServices.name.splice(index, 1);
        delete formErrors.nameSpecial;
      }

      break;
    case "value":
      validate = /^([a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ,.:;!@#$?¿%^&*()-_  ]{1,})$/.test(
        value
      );
      if (!validate) {
        formErrorsServices.value[index] = "Valor invalido";
        formErrors.valueSpecial = "Valor invalido";
      } else {
        formErrorsServices.value && formErrorsServices.value.splice(index, 1);
        delete formErrors.valueSpecial;
      }
      break;

    default:
      break;
  }
  return { formErrors, formErrorsServices };
};

const validateFieldsTariffs = (
  formErrors,
  formErrorsTariffs,
  field,
  value,
  index
) => {
  let validate = false;

  switch (field) {
    case "sex":
      validate = /^([a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ,.:;!@#$?¿%^&*()-_  ]{1,})$/.test(
        value
      );
      if (!validate) {
        formErrorsTariffs.sex[index] = "Sexo inválido";
        formErrors.sex = "Sexo inválido";
      } else {
        formErrorsTariffs.sex && formErrorsTariffs.sex.splice(index, 1);
        delete formErrors.sex;
      }
      break;
    case "kinship":
      validate = /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ]{3,})$/.test(value);
      if (!validate) {
        formErrorsTariffs.kinship[index] = "Parentesco inválido";
        formErrors.kinship = "Parentesco inválido";
      } else {
        formErrorsTariffs.kinship && formErrorsTariffs.kinship.splice(index, 1);
        delete formErrors.kinship;
      }
      break;
    case "min_age":
      validate = /^([0-9]{1,})$/.test(value) && value >= 0;
      if (!validate) {
        formErrorsTariffs.min_age[index] = "Edad inválida";
        formErrors.min_age = "Edad inválida";
      } else {
        formErrorsTariffs.min_age && formErrorsTariffs.min_age.splice(index, 1);
        delete formErrors.min_age;
      }
      break;
    case "max_age":
      validate = /^([0-9]{1,})$/.test(value) && value >= 0;
      if (!validate) {
        formErrorsTariffs.max_age[index] = "Edad inválida";
        formErrors.max_age = "Edad inválida";
      } else {
        formErrorsTariffs.max_age && formErrorsTariffs.max_age.splice(index, 1);
        delete formErrors.max_age;
      }
      break;
    case "price":
      validate = /^([0-9.,]{1,})$/.test(value) && value >= 0;
      if (!validate) {
        formErrorsTariffs.price[index] = "Precio inválido";
        formErrors.price = "Precio inválido";
      } else {
        formErrorsTariffs.price && formErrorsTariffs.price.splice(index, 1);
        delete formErrors.price;
      }
      break;
    default:
      break;
  }
  return { formErrors, formErrorsTariffs };
};

const validateFieldsAddress = (
  formErrors,
  formErrorsAddress,
  field,
  value,
  index
) => {
  let validate = false;

  switch (field) {
    case "city":
      validate = /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ]{3,})$/.test(value);

      if (!validate) {
        formErrorsAddress.city[index] = "Ciudad invalida";
        formErrors.city = "Ciudad invalida";
      } else {
        formErrorsAddress.city.splice(index, 1);
        delete formErrors.city;
      }
      break;
    case "address":
      validate = /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ0-9;:.,\-# ]{3,})$/.test(value);
      if (!validate) {
        formErrorsAddress.address[index] = "Direccion invalida";
        formErrors.address = "Direccion invalida";
      } else {
        delete formErrorsAddress.address.splice(index, 1);
        delete formErrors.address;
      }
      break;

    default:
      break;
  }
  return { formErrors, formErrorsAddress };
};

const validateFieldsPaymentForms = (
  formErrors,
  formErrorsPaymentForm,
  field,
  value,
  index
) => {
  let validate = false;

  switch (field) {
    case "value":
      validate = /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ]{1,})$/.test(value);
      if (!validate) {
        formErrorsPaymentForm.value[index] = "Valor invalido";
        formErrors.value = "Valor invalido";
      } else {
        formErrorsPaymentForm.value.splice(index, 1);
        delete formErrors.value;
      }
      break;
    case "name":
      validate = /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ]{1,})$/.test(value);
      if (!validate) {
        formErrorsPaymentForm.name[index] = "Nombre invalido";
        formErrors.name = "Nombre invalido";
      } else {
        delete formErrorsPaymentForm.name.splice(index, 1);
        delete formErrors.name;
      }
      break;
    case "recharge":
      validate = /^([0-9.,]{1,})$/.test(value);
      if (!validate) {
        formErrorsPaymentForm.recharge[index] = "Recargo invalido";
        formErrors.recharge = "Recargo invalido";
      } else {
        delete formErrorsPaymentForm.recharge.splice(index, 1);
        delete formErrors.recharge;
      }
      break;
    default:
      break;
  }
  return { formErrors, formErrorsPaymentForm };
};

const validateFieldsBasicCoupons = (formErrors, field, value) => {
  let validate = false;

  switch (field) {
    case "full_name":
      validate = /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ]{3,})$/.test(value);
      !validate
        ? (formErrors.full_name = "Nombre del promotor inválido.")
        : delete formErrors.full_name;
      break;
    case "id":
      validate = /^([0-9a-zA-ZñÑáéíóúÁÉÍÓÚüÜ_-]{1,})$/.test(value);
      if (!validate) {
        formErrors.id = "Valor invalido";
      } else {
        delete formErrors.id;
      }
      break;
    case "percent_off":
      validate = /^([0-9.,]{1,})$/.test(value) && value >= 0;
      if (!validate) {
        formErrors.percent_off = "Porcentaje inválido";
      } else {
        delete formErrors.percent_off;
      }
      break;
    case "duration":
      validate = ["forever", "once", "repeating"].includes(value._id);
      if (!validate) {
        formErrors.duration = "Duración inválida";
      } else {
        delete formErrors.duration;
      }
      break;
    case "duration_in_months":
      validate = /^([0-9.,]{1,})$/.test(value) && value >= 0;
      if (!validate) {
        formErrors.duration_in_months = "Duración inválida";
      } else {
        delete formErrors.duration_in_months;
      }
      break;
    case "max_redemptions":
      validate = /^([0-9.,]{1,})$/.test(value) && value >= 0;
      if (!validate) {
        formErrors.max_redemptions = "Cantidad inválida";
      } else {
        delete formErrors.max_redemptions;
      }
      break;
    case "redeem_by":
      const date = value ? new Date(value) : null;
      validate = isValidDate(date);
      if (!validate) {
        formErrors.redeem_by = "Cantidad inválida";
      } else {
        delete formErrors.redeem_by;
      }
      break;
    case "nickname":
      validate = /^([a-zA-Z0-9-]{3,})$/.test(value);
      !validate
        ? (formErrors.nickname = "Apodo inválido.")
        : delete formErrors.nickname;
      break;
    case "commision":
      validate = /^([0-9.,]{1,})$/.test(value) && value >= 0;
      !validate
        ? (formErrors.commision = "Comisión inválida.")
        : delete formErrors.commision;
      break;
    default:
      break;
  }
  return { formErrors };
};

const validateFieldsCoupons = (
  formErrors,
  formErrorsCoupons,
  field,
  value,
  index
) => {
  let validate = false;

  switch (field) {
    case "id":
      validate = /^([0-9a-zA-ZñÑáéíóúÁÉÍÓÚüÜ_-]{1,})$/.test(value);
      if (!validate) {
        formErrorsCoupons.id[index] = "Valor invalido";
        formErrors.id = "Valor invalido";
      } else {
        formErrorsCoupons.id.splice(index, 1);
        delete formErrors.id;
      }
      break;
    case "percent_off":
      validate = /^([0-9.,]{1,})$/.test(value) && value >= 0;
      if (!validate) {
        formErrorsCoupons.percent_off[index] = "Porcentaje inválido";
        formErrors.percent_off = "Porcentaje inválido";
      } else {
        delete formErrorsCoupons.percent_off.splice(index, 1);
        delete formErrors.percent_off;
      }
      break;
    case "duration":
      validate = ["forever", "once", "repeating"].includes(value._id);
      if (!validate) {
        formErrorsCoupons.duration[index] = "Duración inválida";
        formErrors.duration = "Duración inválida";
      } else {
        delete formErrorsCoupons.duration.splice(index, 1);
        delete formErrors.duration;
      }
      break;
    case "duration_in_months":
      validate = /^([0-9.,]{1,})$/.test(value) && value >= 0;
      if (!validate) {
        formErrorsCoupons.duration_in_months[index] = "Duración inválida";
        formErrors.duration_in_months = "Duración inválida";
      } else {
        delete formErrorsCoupons.duration_in_months.splice(index, 1);
        delete formErrors.duration_in_months;
      }
      break;
    case "max_redemptions":
      validate = /^([0-9.,]{1,})$/.test(value) && value >= 0;
      if (!validate) {
        formErrorsCoupons.max_redemptions[index] = "Cantidad inválida";
        formErrors.max_redemptions = "Cantidad inválida";
      } else {
        delete formErrorsCoupons.max_redemptions.splice(index, 1);
        delete formErrors.max_redemptions;
      }
      break;
    case "redeem_by":
      const date = value ? new Date(value) : null;
      validate = isValidDate(date);
      if (!validate) {
        formErrorsCoupons.redeem_by[index] = "Cantidad inválida";
        formErrors.redeem_by = "Cantidad inválida";
      } else {
        delete formErrorsCoupons.redeem_by.splice(index, 1);
        delete formErrors.redeem_by;
      }
      break;

    default:
      break;
  }
  return { formErrors, formErrorsCoupons };
};

const validateFieldsVoluntary = (formErrors, field, value) => {
  let validate = false;

  const isValidDate = (date) => {
    return date instanceof Date && !isNaN(date);
  };

  switch (field) {
    case "first_name":
      validate = /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ]{3,})$/.test(value);
      !validate
        ? (formErrors.first_name = "Nombres inválidos.")
        : delete formErrors.first_name;
      break;
    case "last_name":
      validate = /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ]{3,})$/.test(value);
      !validate
        ? (formErrors.last_name = "Apellidos inválidos.")
        : delete formErrors.last_name;
      break;
    case "document_type":
      validate = value !== "" && value !== "default";
      !validate
        ? (formErrors.document_type = "Debe seleccionar el tipo de documento.")
        : delete formErrors.document_type;
      break;
    case "dni":
      validate = /^([0-9a-zA-Z]{6,20})$/.test(value);
      !validate
        ? (formErrors.dni = "Debe colocar un número de documento válido.")
        : delete formErrors.dni;
      break;
    case "cell_phone":
      validate = /^([0-9()+ -]{10,})$/.test(value);
      !validate
        ? (formErrors.cell_phone =
            "Teléfono inválido. Ejemplo: (+58) 416 765-432")
        : delete formErrors.cell_phone;
      break;
    case "email":
      validate = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(value);
      !validate
        ? (formErrors.email = "Correo electrónico inválido.")
        : delete formErrors.email;
      break;
    case "birth_date":
      let date = value ? new Date(value) : null;
      let isDate = isValidDate(date);
      !isDate || date > new Date()
        ? (formErrors.birth_date = "Debe seleccionar la fecha de nacimiento.")
        : delete formErrors.birth_date;
      break;
    case "country":
      validate = value !== "" && value !== "default";
      !validate
        ? (formErrors.country = "Debe seleccionar un país.")
        : delete formErrors.country;
      break;
    case "city":
      validate =
        /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ,.-]{2,})$/.test(value) && value !== "default";
      !validate
        ? (formErrors.city = "Debe ingresar una ciudad válida.")
        : delete formErrors.city;
      break;
    case "specialty":
      validate =
        /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ -]{3,})$/.test(value.name) &&
        value.name !== "default";
      !validate
        ? (formErrors.specialty = "Debe seleccionar una especialidad.")
        : delete formErrors.specialty;
      break;
    case "msds":
      validate = /^([a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ ]{3,})$/.test(value);
      !validate
        ? (formErrors.msds =
            "M.S.D.S  Ministerio de Salud y Desarollo Social inválido.")
        : delete formErrors.msds;
      break;
    case "medical_college":
      validate =
        /^([a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ ]{3,})$/.test(value) || value === "";
      !validate
        ? (formErrors.medical_college =
            "Número de colegio de médicos inválido.")
        : delete formErrors.medical_college;
      break;
    case "doc_dni":
      const typeDni = value && value.type ? value.type : null;
      !(
        typeDni === "application/pdf" ||
        typeDni === "image/jpeg" ||
        typeDni === "image/jpg" ||
        typeDni === "image/png"
      )
        ? (formErrors.doc_dni =
            "Archivo inválido, solo archivos .pdf, .png, .jpg, .jpeg")
        : delete formErrors.doc_dni;
      break;
    case "doc_medical_title":
      const typeTitle = value && value.type ? value.type : null;
      !(
        typeTitle === "application/pdf" ||
        typeTitle === "image/jpeg" ||
        typeTitle === "image/jpg" ||
        typeTitle === "image/png"
      )
        ? (formErrors.doc_medical_title =
            "Archivo inválido, solo archivos .pdf, .png, .jpg, .jpeg")
        : delete formErrors.doc_medical_title;
      break;
    case "video":
      const typeVideo = value && value.type ? value.type : null;
      !(
        typeVideo === "video/mov" ||
        typeVideo === "video/avi" ||
        typeVideo === "video/wmv" ||
        typeVideo === "video/mp4" ||
        typeVideo === "video/quicktime" ||
        value === null
      )
        ? (formErrors.video =
            "Archivo inválido, solo archivos .mp4, .avi, .mov, .wmv")
        : delete formErrors.video;
      break;
    default:
      break;
  }
  return { formErrors };
};

const validateFieldsDemographic = (
  formErrors,
  formErrorsDemographic,
  field,
  value,
  index
) => {
  let validate = false;

  switch (field) {
    case "sex":
      validate = /^([a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ,.:;!@#$?¿%^&*()-_  ]{1,})$/.test(
        value
      );
      if (!validate) {
        formErrorsDemographic.sex[index] = "Sexo inválido";
        formErrors.sex = "Sexo inválido";
      } else {
        formErrorsDemographic.sex && formErrorsDemographic.sex.splice(index, 1);
        delete formErrors.sex;
      }
      break;
    case "price_by_age":
      validate = /^([0-9.,]{1,})$/.test(value) && value >= 0;
      if (!validate) {
        formErrorsDemographic.price_by_age[index] = "Precio inválido";
        formErrors.price_by_age = "Precio inválido";
      } else {
        formErrorsDemographic.price_by_age &&
          formErrorsDemographic.price_by_age.splice(index, 1);
        delete formErrors.price_by_age;
      }
      break;
    case "min_age":
      validate = /^([0-9]{1,})$/.test(value) && value >= 0;
      if (!validate) {
        formErrorsDemographic.min_age[index] = "Edad inválida";
        formErrors.min_age = "Edad inválida";
      } else {
        formErrorsDemographic.min_age &&
          formErrorsDemographic.min_age.splice(index, 1);
        delete formErrors.min_age;
      }
      break;
    case "max_age":
      validate = /^([0-9]{1,})$/.test(value) && value >= 0;
      if (!validate) {
        formErrorsDemographic.max_age[index] = "Edad inválida";
        formErrors.max_age = "Edad inválida";
      } else {
        formErrorsDemographic.max_age &&
          formErrorsDemographic.max_age.splice(index, 1);
        delete formErrors.max_age;
      }
      break;
    case "coverage":
      validate = /^([0-9.,]{1,})$/.test(value) && value >= 0;
      if (!validate) {
        formErrorsDemographic.coverage[index] = "Cobertura inválido";
        formErrors.coverage = "Cobertura inválido";
      } else {
        formErrorsDemographic.coverage &&
          formErrorsDemographic.coverage.splice(index, 1);
        delete formErrors.coverage;
      }
      break;
    default:
      break;
  }
  return { formErrors, formErrorsDemographic };
};

const validateFieldPassword = (field, value, formErrors, signUpPass) => {
  let form = formErrors;
  let validate = false;
  switch (field) {
    case 'password':
      validate = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@_#\$%\^&\*\.\+\-])(?=.{8,20})/.test(value);

      (!validate)
          ? form.password = "La contraseña debe tener mínimo de 8 y un máximo 20 carácteres, al menos una mayúscula, un cáracter especial y al menos un número."
          : delete form.password;
          if(signUpPass?.password && !form.password) {
            value === signUpPass?.password_confirm ? delete form.password_confirm : form.password_confirm = "La contraseña no coincide.";
          }
      break;
    case "password_confirm":
      validate = value === signUpPass?.password;
      !validate
        ? (form.password_confirm = "La contraseña no coincide.")
        : delete form.password_confirm;
      break;
    case "email":
      validate = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(value);
      !validate ? (form.email = "Correo inválido.") : delete form.email;
      break;
    case "code":
      validate = /^[0-9]*$/.test(value);
      !validate
        ? (form.code = "Código inválido, debe ser numérico.")
        : delete form.code;
      break;
    default:
      break;
  }
  return form;
};

// TODO: Validation only for characters in sign in, password generated from adviser not match regex password validations
const validateFieldPasswordOnlyCharacters = (field, value, formErrors, signUpPass) => {
  let form = formErrors;
  let validate = false;
  switch (field) {
    case 'password':
      validate = /^([a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ !@#\$%\^&\*\.\+]{8,})$/.test(value);

      (!validate)
          ? form.password = "La contraseña debe tener mínimo de 8 y un máximo 20 carácteres, al menos una mayúscula, un cáracter especial y al menos un número."
          : delete form.password;
          if(signUpPass?.password && !form.password) {
            value === signUpPass?.password_confirm ? delete form.password_confirm : form.password_confirm = "La contraseña no coincide.";
          }
      break;
    default:
      break;
  }
  return form;
};
export default {
  validateFieldHeadLine,
  validateFieldHeadLineRequired,
  validateTitularForm,
  validateFieldBeneficiaries,
  validatePaymentForm,
  validateFieldBeneficiariesRequired,
  validateFieldContact,
  validFieldInitAffiliation,
  validateFieldsProduct,
  validateOptionsProduct,
  validateServicesProduct,
  validateFieldsTariffs,
  validateFieldsAddress,
  validateFieldsPaymentForms,
  validateFieldsCoupons,
  validateFieldsBasicCoupons,
  validateFieldsVoluntary,
  isValidDate,
  validateFieldsDemographic,
  validateFieldPassword,
  validateFieldPasswordOnlyCharacters,
}
