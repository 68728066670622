export const SIGNIN = "SIGNIN";
export const SIGNIN_ALLY = "SIGNIN_ALLY";
export const LOGOUT = "LOGOUT";
export const USERUPDATE = "USERUPDATE";

export const CAMPAIGNS = "CAMPAIGNS";
export const SELECTCAMPAIGN = "SELECTCAMPAIGN";

export const SAVETITULARDATA = "SAVETITULARDATA";
export const SAVEBENEFICIARIESDATA = "SAVEBENEFICIARIESDATA";
export const UPDATE_TITULAR_DATA = "UPDATE_TITULAR_DATA";

export const USERLOGS = "USERLOGS";
export const SEARCHUSERLOGS = "SEARCHUSERLOGS";

export const SEARCHDASHBOARD = "SEARCHDASHBOARD";
export const NEXT_AFFILIATION_DATA = "NEXT_AFFILIATION_DATA";
export const BACKTODASHBOARD = "BACKTODASHBOARD";
export const VIEWREGISTRY = "VIEWREGISTRY";
export const RENEWSUBSCRIPTIONS = "RENEWSUBSCRIPTIONS";

export const PENDINGDASHBOARD = "PENDINGDASHBOARD";
export const UPDATEPAYMENTCUSTOMER = "UPDATEPAYMENTCUSTOMER";

export const SAVE_MEDICAL_CONSULTS = "SAVE_MEDICAL_CONSULTS";
export const SAVE_CONSULT_BY_ID = "SAVE_CONSULT_BY_ID";

// MARKETING CAMPAIGNS
export const GETCAMPAINGS = "GETCAMPAINGS";
export const GETCAMPAIGNINFO = "GETCAMPAIGNINFO";
export const UPDATE_CURRENT_CAMPAIGN = "UPDATE_CURRENT_CAMPAIGN";
export const RESET_CURRENT_CAMPAIGN = "RESET_CURRENT_CAMPAIGN";
export const GET_PROPOSALS = "GET_PROPOSALS";
export const SET_PROPOSAL = "SET_PROPOSAL";
export const CLEAN_PROPOSAL = "CLEAN_PROPOSAL";

export const SEARCHPAGES = "SEARCHPAGES";
export const TABPOSITION = "TABPOSITION";
export const SEARCHROWSPERPAGE = "SEARCHROWSPERPAGE";

// USERS
export const GETALLUSERS = "GETALLUSERS";
export const UPDATE_MANAGED_USER = "UPDATE_MANAGED_USER";
export const RESET_MANAGED_USER = "RESET_MANAGED_USER";

// AFFILIATIONS
export const CUSTOMER_VALIDATION = "CUSTOMER_VALIDATION";
export const SELECT_CAMPAIGN = "SELECT_CAMPAIGN";
export const ALL_PRODUCTS = "ALL_PRODUCTS";
export const SELECT_PLAN = "SELECT_PLAN";
export const CHECKED_TERMS = "CHECKED_TERMS";
export const CHANGE_VALUE_INPUT_AFFILIATION = "CHANGE_VALUE_INPUT_AFFILIATION";
export const CHANGE_STATE_AFFILIATION = "CHANGE_STATE_AFFILIATION";
export const CALCULATE_TOTAL_PAYMENT = "CALCULATE_TOTAL_PAYMENT";
export const CLEAN_AFFILIATION = "CLEAN_AFFILIATION";
export const CLEAN_AFFILIATION_ADMIN = "CLEAN_AFFILIATION_ADMIN";
export const CLEAN_FORM_ERRORS_BENEFICIARIES =
  "CLEAN_FORM_ERRORS_BENEFICIARIES";

export const SAVE_USERID = "SAVE_USERID";
export const SAVE_TOKEN = "SAVE_TOKEN";
export const SAVE_USER_TYPE = "SAVE_USER_TYPE";
export const SAVE_USER_EMAIL = "SAVE_USER_EMAIL";
export const USE_TITULAR_DATA = "USE_TITULAR_DATA";
export const SAVE_TITULAR_EMAIL = "SAVE_TITULAR_EMAIL";
export const SHOW_DISCOUNT_PERCENT = "SHOW_DISCOUNT_PERCENT";
export const SET_IS_CLIENT = "SET_IS_CLIENT";
export const SAVE_ALLY_NAME = "SAVE_ALLY_NAME";
export const SET_INTERVAL = "SET_INTERVAL";
export const SET_ALL_INTERVALS = "SET_ALL_INTERVALS";

// PROFILE
export const PROFILE_STEP = "PROFILE_STEP";
export const SELECT_BENEFICIARY = "SELECT_BENEFICIARY";
export const SHOW_EDIT_DIALOG = "SHOW_EDIT_DIALOG";
export const UPDATE_PAYMENT_INFO = "UPDATE_PAYMENT_INFO";
export const GET_ALL_CANCELLATION = "GET_ALL_CANCELLATION";
export const SET_CANCELLATION = "SET_CANCELLATION";
export const CLEAN_CANCELLATION = "CLEAN_CANCELLATION";
export const SAVE_LINK = "SAVE_LINK";
export const RENEW_PAYMENT = "RENEW_PAYMENT";
export const RENEW_ERROR = "RENEW_ERROR";
export const RENEW_LOADING = "RENEW_LOADING";

// ALLYS
export const GET_ALLYS = "GET_ALLYS";
export const SAVE_ALLY_SHARED_LINK = "SAVE_ALLY_SHARED_LINK";

// SOS-19
export const SAVE_GENERAL_DATA = "SAVE_GENERAL_DATA";
export const SAVE_COVID_DATA = "SAVE_COVID_DATA";
export const SAVE_MOTIVES = "SAVE_MOTIVES";
export const SAVE_MEDICAL_QUESTIONS = "SAVE_MEDICAL_QUESTIONS";
export const MEDICAL_RESULT = "MEDICAL_RESULT";
export const CLEAN_DATA = "CLEAN_DATA";
export const CLEAN_COVID_DATA = "CLEAN_COVID_DATA";

// LEADS
export const GET_LEADS = "GET_LEADS";
export const SAVE_NEW_LEAD = "SAVE_NEW_LEAD";
export const CLEAN_NEW_LEAD = "CLEAN_NEW_LEAD";
export const SAVE_LEAD_EMAIL = "SAVE_LEAD_EMAIL";
export const SAVE_ORIGIN_LEAD = "SAVE_ORIGIN_LEAD";
export const SAVE_STATUS_LEAD = "SAVE_STATUS_LEAD";

export const GET_VOLUNTEERS = "GET_VOLUNTEERS";
export const SAVE_VOLUNTARY_DATA = "SAVE_VOLUNTARY_DATA";
export const CLEAN_VOLUNTARY_DATA = "CLEAN_VOLUNTARY_DATA";
export const SAVE_CONSULTATION_FEEDBACK = "SAVE_CONSULTATION_FEEDBACK";
export const CLEAN_MEDICAL_CONSULT = "CLEAN_MEDICAL_CONSULT";

export const VOLUNTEERS_BY_SPECIALTY = "VOLUNTEERS_BY_SPECIALTY";

// PRODUCT - PAYMENT METHOD - ADDON - VALIDATOR
export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_SURVEYS = "GET_SURVEYS";
export const GET_PAYMENT_METHODS = "GET_PAYMENT_METHODS";
export const GET_ADDONS = "GET_ADDONS";
export const GET_VALIDATORS = "GET_VALIDATORS";
export const GET_PROMOTIONS = "GET_PROMOTIONS";
export const GET_ACTIVE_PROMOTIONS = "GET_ACTIVE_PROMOTIONS";

// COUPON / PROMOTER
export const SET_PROMOTER = "SET_PROMOTER";
export const CLEAN_PROMOTER = "CLEAN_PROMOTER";
export const GET_PROMOTERS = "GET_PROMOTERS";
export const GET_PROMOTERS_BY_COUPON = "GET_PROMOTERS_BY_COUPON";
export const MANAGE_PROMOTER = "MANAGE_PROMOTER";

// COMMISSIONS
export const GET_COMMISSIONS = "GET_COMMISSIONS";
export const SET_COMMISSIONS = "SET_COMMISSIONS";
export const SET_COMMISSION = "SET_COMMISSION";

// ADMIN NAVBAR
export const SET_OPEN_DRAWER = "SET_OPEN_DRAWER";

// ALLY POINTS & CONDITIONS
export const SET_POINTS = "SET_POINTS";
export const SET_POINT = "SET_POINT";
export const SET_CONDITIONS = "SET_CONDITIONS";
export const SET_CONDITION = "SET_CONDITION";
export const SET_LIST_PARAMETERS = "SET_LIST_PARAMETERS";
export const SET_AWARDS = "SET_AWARDS";
export const SET_AWARD = "SET_AWARD";

// QUOTATION
export const SAVE_QUOTATION_DATA = "SAVE_QUOTATION_DATA";
export const SAVE_QUOTATION_CLIENT = "SAVE_QUOTATION_CLIENT";
export const CLEAN_QUOTATION = "CLEAN_QUOTATION";

// BIGBRODER
export const SET_BIGBRODER = "SET_BIGBRODER";
export const SET_BIGBRODERS = "SET_BIGBRODERS";
export const SET_BIGBRODERS_CANDIDATE = "SET_BIGBRODERS_CANDIDATE";

// PAYMENTS
export const UPDATE_PAYMENT_FEES = "UPDATE_PAYMENT_FEES";
export const SAVE_TDC_PAYMENTS = "SAVE_TDC_PAYMENTS";
export const SAVE_OVERDUE_PAYMENTS = "SAVE_OVERDUE_PAYMENTS";
export const SAVE_PENDING_PAYMENTS = "SAVE_PENDING_PAYMENTS";

// COUNTRY
export const SET_CURRENT_COUNTRY = "SET_CURRENT_COUNTRY";

// HEADER DATE SEARCH
export const SET_COUNTRIES = "SET_COUNTRIES";
export const SET_COUNTRY = "SET_COUNTRY";
export const SET_REGIONS = "SET_REGIONS";
export const SET_REGION = "SET_REGION";
export const SET_CURRENT_SEARCH_DATE = "SET_CURRENT_SEARCH_DATE";

// PROJECTIONS
export const SET_PROJECTIONS = "SET_PROJECTIONS";
export const SET_PROJECTION = "SET_PROJECTION";

// VERIFICATIONS
export const GET_VERIFICATIONS = "GET_VERIFICATIONS";
export const SET_VERIFICATIONS = "SET_VERIFICATIONS";
export const SET_VERIFICATION = "SET_VERIFICATION";

// MEMBERSHIP
export const SET_ORIGIN = "SET_ORIGIN";

// CURRENCY
export const SET_CURRENCY = "SET_CURRENCY";
export const SET_ALL_CURRENCIES = "SET_ALL_CURRENCIES";

// SIGN UP
export const SET_SIGNUP_DATA = "SET_SIGNUP_DATA";
