import {
  SET_ORIGIN,
} from '../actionTypes';
import Common from '../../src/services/common.js';

export const setMembershipOrigin = (values) => {
  return async dispatch => await dispatch({
    type: SET_ORIGIN,
    payload: {
      values
    }
  })
};

export const createNewMember = (values) => {
  return async dispatch => {
    return await Common.create_new_member(values)
    .then(async (r) => {
      return {
        openAlert: true,
        messageAlert: 'Agregaste un nuevo beneficiario existosamente.',
        success: true,
      }
    })
    .catch((e) => {
      return {
        openAlert: true,
        messageAlert: e?.response?.data?.error?.message ? e.response.data.error.message : 'Hubo un error al agregar el nuevo beneficiario, intente nuevamente.',
      }
    })
  }
}
