import {
  GET_LEADS, SAVE_NEW_LEAD, CLEAN_NEW_LEAD, SAVE_LEAD_EMAIL, SAVE_ORIGIN_LEAD, SAVE_STATUS_LEAD, SEARCHROWSPERPAGE,
} from '../actionTypes';
import Common from '../../src/services/common.js';

const FileDownload = require('js-file-download');

export const getLeads = (status) => {
  return async dispatch => {
    return await Common.get_leads(status)
    .then(async (r) => {
      await dispatch({
        type: SEARCHROWSPERPAGE,
        payload: { values: { page: 0, rowsPerPage: 5 } },
      });
      await dispatch({
        type: GET_LEADS,
        payload: {
          values: r.data.leads
        }
      });
      let result = {
        spinnerScreen: false,
      }
      return result;
    })
    .catch((e) => {
      return {
        spinnerScreen: false,
        openAlert: true,
        messageAlert: e.response && e.response.data ?  e.response.data.error.message : 'Hubo un error al obtener los leads, intente nuevamente.',
      }
    })
  }
}

export const newLead = (lead) => {
  return async () => {
    try {
      await Common.leads(lead);
      return {
        success: true,
        message: '¡Gracias! Te hemos enviado un correo electrónico con toda la información de asistensi',
      }
    } catch (e) {
      return {
        success: false,
        message: e.response && e.response.data ? e.response.data.error.message : 'Hubo un error al consultar sus datos, intente nuevamente.'
      }
    }
  }

}

/**
* -------------------------------------------------------
* @summary sendDataCookie
* @description Send DAta cookie - quotation
* -------------------------------------------------------
*/
export const sendDataCookie = (lead) => {
  return async () => {
    try {
      await Common.leads(lead);
      return {
        success: true,
        message: '¡Gracias! Te hemos enviado un correo electrónico con toda la información de asistensi',
      }
    } catch (e) {
      return {
        success: false,
        message: e.response && e.response.data ? e.response.data.error.message : 'Hubo un error al consultar sus datos, intente nuevamente.'
      }
    }
  }
}


export const saveNewLead = (values) => {
  return async dispatch => {
    return await dispatch({
      type: SAVE_NEW_LEAD,
      payload: {
        values
      }
    })
  }
}

export function getLeadExport(data) {
  return async () => {
    return await Common.export_lead({ responseType: "blob" }, data)
      .then(async res => {
        await FileDownload(res.data, 'leads-consolidado.xlsx')
        return {
          showSpinner: false,
        }
      })
      .catch(e => {
        return {
          showSpinner: false,
          openAlert: true,
          messageAlert: e.response && e.response.data ?  e.response.data.error.message :  'Hubo un error al descargar el archivo, intente nuevamente.',
        };
      })
  }
}

export const createLead = (lead) => {
  return async dispatch => {
    return await Common.create_lead(lead)
    .then(async () => {
      return {
        success: true,
        spinnerScreen: false,
        openAlert: true,
        messageAlert: 'Nuevo lead registrado exitosamente.',
      }
    })
    .catch((e) => {
      return {
        success: false,
        spinnerScreen: false,
        openAlert: true,
        messageAlert: e.response && e.response.data ?  e.response.data.error.message : 'Hubo un error al crear el lead, intente nuevamente.',
      }
    })
  }
}

export const updateLead = (lead) => {
  return async dispatch => {
    return await Common.update_lead(lead)
    .then(async (r) => {
      return {
        success: true,
        spinnerScreen: false,
        openAlert: true,
        messageAlert: 'Lead actualizado exitosamente.',
        lead: r.data.data
      }
    })
    .catch((e) => {
      return {
        success: false,
        spinnerScreen: false,
        openAlert: true,
        messageAlert: e.response && e.response.data ?  e.response.data.error.message : 'Hubo un error al crear el lead, intente nuevamente.',
      }
    })
  }
}

export const getOriginFilter = (status) => {
  return async dispatch => {
    return await Common.get_origin_filter(status).then(async (r) => {
      await dispatch({
        type: SAVE_ORIGIN_LEAD,
        payload: r.data.origins || [],
      });
      let result = {
        spinnerScreen: false,
      }
      return result;
    }).catch((e) => {
      return {
        spinnerScreen: false,
      }
    })
  }
}

export const getStatusFilter = (status) => {
  return async dispatch => {
    return await Common.get_status_filter(status).then(async (r) => {
      await dispatch({
        type: SAVE_STATUS_LEAD,
        payload: r.data.status
      });
      let result = {
        spinnerScreen: false,
      }
      return result;
    }).catch((e) => {
      return {
        spinnerScreen: false,
      }
    })
  }
}

export const cleanNewLead = () => async dispatch => await dispatch({ type: CLEAN_NEW_LEAD, payload: [] });

// LANDING PAGES

export function createNewLead(lead) {
  return async dispatch => {
    return await Common.leads(lead)
    .then(res => {
      return {
        success: true,
        spinnerScreen: false,
        openAlert: true,
        messageAlert: '¡Gracias! Tu solicitud ha sido procesada exitosamente',
      }
    })
    .catch(e => {
      return {
        spinnerScreen: false,
        openAlert: true,
        messageAlert: e?.response?.data?.error?.message ? e.response.data.error.message : 'Hubo un error al enviar sus datos, intente nuevamente.',
      }
    })
  }
}

export const saveLeadEmail = (values) => {
  return async dispatch => {
    return await dispatch({
      type: SAVE_LEAD_EMAIL,
      payload: {
        values
      }
    })
  }
}

export const getLocation = () => {
  return async dispatch => {
    return await Common.get_location_ip()
    .then(response => {
      return {
        country: response.data?.data?.country,
        ip: response.data?.data?.query,
        countryCode: response.data?.data?.countryCode,
        ip_data: response.data?.data,
      }
    })
    .catch((e) => {
      return e
    })
  }
}