import {SET_CURRENT_SEARCH_DATE} from '../actionTypes';
import Common from '../../src/services/common';

export const setCurrentSearchDates = (dates) => async dispatch => {
  return await dispatch({
    type: SET_CURRENT_SEARCH_DATE,
    payload: dates,
  });
};

// SALES
export const getPoliciesByDate = (start, end) => {
  return async dispatch => {
    return Common.get_policies_by_date(start, end).then(async (r) => {
      return {
        data: r.data,
        success: true,
      };
    }).catch(e => {
      return {
        spinnerScreen: false,
        openAlert: true,
        messageAlert: e.response && e.response.data ?  e.response.data.error.message : 'Hubo un error al obtener los datos, intente nuevamente.',
      }
    });
  };
};

export const getTotalAmountsByDate = (start, end) => {
  return async dispatch => {
    return Common.get_total_amounts_by_date(start, end).then(async (r) => {
      return {
        data: r.data,
        success: true,
      };
    }).catch(e => {
      return {
        spinnerScreen: false,
        openAlert: true,
        messageAlert: e.response && e.response.data ?  e.response.data.error.message : 'Hubo un error al obtener los datos, intente nuevamente.',
      }
    });
  };
};

export const getPlansByDate = (start, end) => {
  return async dispatch => {
    return Common.get_plans_by_date(start, end).then(async (r) => {
      return {
        data: r.data,
        success: true,
      };
    }).catch(e => {
      return {
        spinnerScreen: false,
        openAlert: true,
        messageAlert: e.response && e.response.data ?  e.response.data.error.message : 'Hubo un error al obtener los datos, intente nuevamente.',
      }
    });
  };
};

export const getSalesProfile = (start, end) => {
  return async dispatch => {
    return Common.get_sales_profile(start, end).then(async (r) => {
      return {
        data: r.data,
        success: true,
      };
    }).catch(e => {
      return {
        spinnerScreen: false,
        openAlert: true,
        messageAlert: e.response && e.response.data ?  e.response.data.error.message : 'Hubo un error al obtener los datos, intente nuevamente.',
      }
    });
  };
};

export const getSalesMetrics = (country) => {
  return async dispatch => {
    return Common.get_sales_metrics(country).then(async (r) => {
      return {
        data: r.data,
        success: true,
      };
    }).catch(e => {
      return {
        spinnerScreen: false,
        openAlert: true,
        messageAlert: e.response && e.response.data ?  e.response.data.error.message : 'Hubo un error al obtener las metricas, intente nuevamente.',
      }
    });
  };
};
