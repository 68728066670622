import {
  SIGNIN,
  LOGOUT,
  SIGNIN_ALLY,
  SET_OPEN_DRAWER,
} from '../actionTypes';
  
export function SignIn(values) {
  return async dispatch => {
    await dispatch({
      type: SIGNIN,
      payload: {
        values
      }
    });
    return true;
  }
}

export function SignInally(values) {
  return async dispatch => {
    await dispatch({
      type: SIGNIN_ALLY,
      payload: {
        values
      }
    });
    return true;
  }
}

export function Logout() {
  return {
    type: LOGOUT
  }
}

export function setOpenDrawer(value) {
  return async dispatch => {
    await dispatch({
      type: SET_OPEN_DRAWER,
      payload: value,
    });
    return true;
  }
}
