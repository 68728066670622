import {
  SAVE_ALLY_SHARED_LINK,
} from '../actionTypes';
import Common from '../../src/services/common.js';
const FileDownload = require('js-file-download');

export function getMyAllys(date) {

  let dateNow = new Date();
  if (!date) {
    const year = dateNow.getFullYear();
    const month = dateNow.getMonth() + 1;

    dateNow = `${year}-${month <= 9 ? `0${month}` : `${month}`}-1`;
  }
  return async dispatch => {
    return await Common.get_allys(date ? date : dateNow)
      .then(async res => {
        return {
          data: res.data.allies,
          spinnerScreen: false,
        }
      })
      .catch(e => {
        return {
          data: [],
          spinnerScreen: false,
          openAlert: true,
          messageAlert: e === typeof "object" && e.response && e.response.data ? e.response.data.error.message : 'Hubo un error al consultar los usuarios, intente nuevamente.',
        }
      })
  }
}

export function getPaymentsMethodsAlly() {
  return async dispatch => {
    return await Common.get_payments_ally()
      .then(async res => {
        return {
          data: res.data.payment_data,
          spinnerScreen: false,
        }
      })
      .catch(e => {
        return {
          data: [],
          spinnerScreen: false,
          openAlert: true,
          messageAlert: e === typeof "object" && e.response && e.response.data ? e.response.data.error.message : 'Hubo un error al consultar los usuarios, intente nuevamente.',
        }
      })
  }
}

export function getMyAfiliations(date) {
  let dateNow = new Date();
  if (!date) {
    const year = dateNow.getFullYear();
    const month = dateNow.getMonth() + 1;

    dateNow = `${year}-${month <= 9 ? `0${month}` : `${month}`}-1`;
  }

  return async dispatch => {
    return await Common.get_afiliations(date ? date : dateNow)
      .then(async res => {
        return {
          data: res.data.registers,
          spinnerScreen: false,
        };
      })
      .catch(e => {
        return {
          data: [],
          spinnerScreen: false,
          openAlert: true,
          messageAlert: e === typeof "object" && e.response && e.response.data ? e.response.data.error.message : 'Hubo un error al consultar los usuarios, intente nuevamente.',
        }
      })
  }
}

export function send_info(email) {
  return async dispatch => {
    return await Common.resendemail({email})
      .then(async res => {
        return {
          data: res.data.send,
          spinnerScreen: false,
          openAlert: true,
        };
      })
      .catch(e => {
        return {
          data: [],
          spinnerScreen: false,
          openAlert: true,
          messageAlert: e === typeof "object" && e.response && e.response.data ? e.response.data.error.message : 'Hubo un error al enviar el email, intente nuevamente.',
        }
      })
  }
}

export function getMasterAllyList(matrix_id) {
  return async dispatch => {
    return await Common.get_allys_master(matrix_id || '')
      .then(async res => {
        return {
          data: res.data.allies,
        };
      })
      .catch(e => {
        return {
          data: []
        }
      })
  }
}

export function getAllyMatrixList() {
  return async dispatch => {
    return await Common.get_allys_matrix()
    .then(async res => {
      return {
        data: res.data.allies,
      };
    })
    .catch(e => {
      return {
        data: []
      }
    })
  }
}

export function getAllyBalanceMonths() {
  return async dispatch => {
    return await Common.get_balance_ally_months()
    .then(async res => {
      return {
        data: res.data,
      };
    })
    .catch(e => {
      return {
        success: false,
        message: e.response?.data ? e.response?.data?.error?.message : 'Hubo un error al consultar sus datos, intente nuevamente.'
      }
    })
  }
}


export function getAllyBalance(year, month) {
  return async dispatch => {
    return await Common.get_balance_ally_data(year, month)
      .then(async res => {
        return {
          data: res.data,
        };
      })
      .catch(e => {
        return {
          success: false,
          message: e.response?.data ? e.response?.data?.error?.message : 'Hubo un error al consultar sus datos, intente nuevamente.'
        }
      })
  }
}

export const invitationAllys = (values) => {
  return async () => {
    try {
      const response = await Common.invitation_ally(values);
      return {
        success: response.data.created,
        message: 'El aliado fue invitado con exito'
      }
    } catch (e) {
      return {
        success: false,
        message: e.response && e.response.data ? e.response.data.error.message : 'Hubo un error al consultar sus datos, intente nuevamente.'
      }
    }
  }

}
export const requestWithDrawal = (values) => {
  return async () => {
    try {
      await Common.request_withdrawal(values);
      return {
        success: true,
        message: 'Su solicitud ha sido enviada con exito'
      }
    } catch (e) {
      return {
        success: false,
        message: e.response && e.response.data ? e.response.data.error.message : 'Hubo un error al consultar sus datos, intente nuevamente.'
      }
    }
  }

}
export const validateTokenAlly = (value) => {
  return async () => {
    try {
      const response = await Common.validate_ally_token(value);
      return {
        success: true,
        data: response.data,
      }

    } catch (e) {
      return {
        success: false,
        message: e.response && e.response.data && e.response.data.error.errorDetail ? e.response.data.error.errorDetail : 'Hubo un error al consultar sus datos, intente nuevamente.'
      }
    }
  }

}

export const activateAlly = (values) => {
  return async () => {
    try {
      const response = await Common.activateAlly(values);
      return {
        success: response.data.update,
        message: 'Cuenta registrada con exito'
      }
    } catch (e) {
      return {
        success: false,
        message: e.response && e.response.data ? e.response.data.error.message : 'Hubo un error al consultar sus datos, intente nuevamente.'
      }
    }
  }

}

export const getAllAllies = () => async () => {

  return await Common.get_all_allies()
    .then(async res => {
      return {
        data: res.data.allies,
      }
    })
    .catch(e => {
      return {
        data: [],
        spinnerScreen: false,
        openAlert: true,
        messageAlert: e === typeof "object" && e.response && e.response.data ? e.response.data.error.message : 'Hubo un error al consultar los usuarios, intente nuevamente.',
      }
    })

}
export const getAllyDetail = (id) => async () => {

  return await Common.get_ally_details(id)
    .then(async res => {
      return {
        data: res.data.ally,
        success: true,
      }
    })
    .catch(e => {
      return {
        data: [],
        messageAlert: e === typeof "object" && e.response && e.response.data ? e.response.data.error.message : 'Hubo un error al consultar los usuarios, intente nuevamente.',
      }
    })

}

export const getAllyBalanceMonthly = (id) => async () => {
  return await Common.get_ally_balance_monthly(id)
    .then(async res => {
      return {
        data: res.data,
        success: true,
      }
    })
    .catch(e => {
      return {
        data: [],
        openAlert: true,
        messageAlert: e?.response?.data ? e.response?.data?.error?.message : 'Hubo un error al consultar la data, intente nuevamente.',
      }
    })
}

export const getAllyBalanceAdmin = (id, year, month) => async () => {
  return await Common.get_ally_balance_admin(id, year, month)
    .then(async res => {
      return {
        data: res.data,
        success: true,
      }
    })
    .catch(e => {
      return {
        data: [],
        openAlert: true,
        messageAlert: e?.response?.data ? e.response?.data?.error?.message : 'Hubo un error al consultar la data, intente nuevamente.',
      }
    })
}

export const getAlliesDetails = (id, date) => async () => {

  let dateNow = new Date();
  if (!date) {
    const year = dateNow.getFullYear();
    const month = dateNow.getMonth() + 1;

    dateNow = `${year}-${month <= 9 ? `0${month}` : `${month}`}-1`;
  }

  const specs = {
    id: id,
    date: date ? date : dateNow,
  };

  try {
    const response = await Common.get_allys_master_details(specs);
    return {
      data: response.data.allies,
      success: true,
    }

  } catch (e) {
    return {
      data: [],
      messageAlert: e === typeof "object" && e.response && e.response.data ? e.response.data.error.message : 'Hubo un error al consultar los usuarios, intente nuevamente.',
    }
  }

}

export const getAfiliationsAllyDetails = (id, date) => async () => {
  let dateNow = new Date();
  if (!date) {
    const year = dateNow.getFullYear();
    const month = dateNow.getMonth() + 1;

    dateNow = `${year}-${month <= 9 ? `0${month}` : `${month}`}-1`;
  }

  const specs = {
    id: id,
    date: date ? date : dateNow,
  };

  try {
    const response = await Common.get_affiliations_allies_details(specs);
    return {
      data: response.data.registers,
    };

  } catch (e) {
    return {
      data: [],
      messageAlert: e === typeof "object" && e.response && e.response.data ? e.response.data.error.message : 'Hubo un error al consultar los usuarios, intente nuevamente.',
    }
  }

}


export const UpdatePaymentMethodsAlly = (values) => async () => {

  try {
    const response = await Common.update_payment_methods_ally(values);
    return {
      success: response.data.update,
      message: 'Datos actualizados'
    }
  } catch (e) {
    return {
      success: false,
      message: e.response && e.response.data ? e.response.data.error.message : 'Hubo un error al consultar sus datos, intente nuevamente.'
    }
  }

}

export const searchAllies = (values) => async () => {

  try {
    const response = await Common.search_allies(values);
    return {
      data: response.data.allies,
      success: true,
    };

  } catch (e) {
    return {
      data: [],
      success: false,
      message: e.response && e.response.data ? e.response.data.error.message : 'Hubo un error al consultar los usuarios, intente nuevamente.',
    }
  }

}

export const onUpdateAlly = (values, id) => async () => {

  try {
    await Common.updated_ally(values, id);
    return {
      success: true,
      message: 'Actualiado con exito'
    };

  } catch (e) {
    return {
      success: false,
      message: e.response && e.response.data ? e.response.data.error.message : 'Hubo un error al consultar los usuarios, intente nuevamente.',
    }
  }
}

export const validateLink = (data) => async dispatch => {
  try {
    const response = await Common.validate_shared_link(data);
    dispatch({
      type: SAVE_ALLY_SHARED_LINK,
      payload:  {
        share_link: data.share_link,
        share_link_discount: data.share_link_discount,
      }
    });
    return {
      success: true,
      data: response.data.validate,
    };
  } catch (e) {
    return {
      success: false,
      message: e.response && e.response.data ? e.response.data : 'Hubo un error al consultar los usuarios, intente nuevamente.',
    }
  }
}
export const UpdateLinkAlly = (data) => async dispatch => {

  try {
    const response = await Common.update_shared_link(data);
    dispatch({
      type: SAVE_ALLY_SHARED_LINK,
      payload:  {
        share_link: response.data.ally.share_link,
        share_link_discount: response.data.ally.share_link_discount,
      }
    })
    return {
      share_link: response.data.ally.share_link,
      share_link_discount: response.data.ally.share_link_discount,
      success: true,
      message: 'Link Guardado con exito'
    };

  } catch (e) {
    return {
      success: false,
      message: e.response && e.response.data ? e.response.data.error.errorDetail : 'Hubo un error al consultar los usuarios, intente nuevamente.',
    }
  }
}

export const uploadMasiveAllies = (data) => async dispatch => {

  try {
    const response = await Common.upload_masive_allies(data);
    return {
      success: true,
      message: 'Carga realizada con exito'
    };

  } catch (e) {
    return {
      success: false,
      message: e.response && e.response.data ? e.response.data.error.message : 'Hubo un error al consultar los usuarios, intente nuevamente.',
    }
  }
}

export const getBalancePointAlly = () => async () => {
  return await Common.get_balance_point_ally()
    .then(async res => {
      return {
        data: res.data,
        success: true,
      }
    })
    .catch(e => {
      return {
        data: [],
        messageAlert: e === typeof "object" && e.response && e.response.data ? e.response.data.error.message : 'Hubo un error al consultar los usuarios, intente nuevamente.',
      }
    })
}

export const getPointAlly = (id) => async () => {
  return await Common.get_point_ally(id)
    .then(async res => {
      return {
        data: res.data,
        success: true,
      }
    })
    .catch(e => {
      return {
        data: [],
        messageAlert: e === typeof "object" && e.response && e.response.data ? e.response.data.error.message : 'Hubo un error al consultar los usuarios, intente nuevamente.',
      }
    })
}

export const getAllyBigFamilyType = () => async () => {
  return await Common.get_ally_bigfamily_type()
    .then(async res => {
      return {
        member: res.data.member,
        success: true,
      } 
    })
    .catch(e => {
      return {
        messageAlert: e === typeof "object" && e.response && e.response.data ? e.response.data.error.message : 'Hubo un error al consultar su categoría bigfamily, intente nuevamente.',
      }
    })
}

export const getAllAllyAffiliations = (data) => async () => {
  return await Common.get_all_ally_affiliations(data)
    .then((res)=>{
      return {
        success: true,
        commissions: res.data.commissions,
        count: res.data.total_commissions_rows,
        manual_balance: res.data.manual_balance,
        total_money_withdrawn: res.data.total_money_withdrawn,
        total_pending_confirm: res.data.total_pending_confirm,
        total_pending_payment: res.data.total_pending_payment,
        total_success: res.data.total_success
      };
    })
    .catch((e)=>{
      return {
        openAlert: true,
        messageAlert: e.response && e.response.data ? e.response.data.error.message : 'Hubo un error al consultar las comisiones, intente nuevamente.',
      }
    })
}

export const getAllyBalanceExportById = (data) => async () => {
  return await Common.get_all_ally_affiliations_export(data, {responseType: 'blob'})
    .then(async (res)=>{
      await FileDownload(res.data, "data.xlsx")
      return{
        success: true,
        loaded: false,
        spinnerScreen: false,
        openAlert: true,
        messageAlert: "Export exitoso.",
      }
    })
    .catch((e)=>{
      return {
        openAlert: true,
        messageAlert: e.response && e.response.data ? e.response.data.error.message : 'Hubo un error al obtener el export.',
      }
    })
}