import { SET_SIGNUP_DATA } from '../actionTypes';

export function setSignupData(value) {
    return async dispatch => {
        await dispatch({
            type: SET_SIGNUP_DATA,
            payload: value,
        });
        return true;
    }
}